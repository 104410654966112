import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import image from "../../../assets/images/register.png";
import { makeStyles } from "@mui/styles";
import DropdownBtn from "../../../components/DropdownBtn";
import { Link as RouterLink } from "react-router-dom";
import { RegisterUser } from "../../../api/axios";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "max-content",
    width: "100vw",
    backgroundColor: "#F1EAFF",
    padding: "2rem 15rem",

    [theme.breakpoints.down("lg")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  centerBox: {
    display: "flex",
    height: "auto",
    width: "100%",
    backgroundColor: "red",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  boxLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "40%",
    position: "relative",
    width: "50%",
    background:
      "linear-gradient(180deg, rgba(35, 21, 65, 1) 0%, rgba(226, 178, 255, 1) 210%)",
  },
  boxRight: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    backgroundColor: "white",
    padding: "3rem 4rem",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "1rem",
  },
}));

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  cnumber: "",
  password: "",
  cpassword: "",
  jobTitle: "",
};
const Register = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialValues);
  const [formError, setFormError] = useState(initialValues);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowcPassword = () => setShowcPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    const errors = {
      fname: "",
      lname: "",
      email: "",
      cnumber: "",
      password: "",
      cpassword: "",
    };
    let isValid = true;

    if (!formData.fname.trim()) {
      errors.fname = "First name is required";
      isValid = false;
    }
    if (!formData.lname.trim()) {
      errors.lname = "Last name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }
    if (!formData.cnumber.trim()) {
      errors.cnumber = "Contact number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.cnumber)) {
      errors.cnumber = "Contact number is invalid";
      isValid = false;
    }
    if (!formData.password.trim() && formData.password.length < 6) {
      errors.password = "Password is required";
      isValid = false;
    } else if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number";
      isValid = false;
    }
    if (!formData.cpassword.trim()) {
      errors.cpassword = "Confirm password is required";
      isValid = false;
    }
    if (formData.password !== formData.cpassword) {
      errors.cpassword = "Password does not match";
      isValid = false;
    }
    if (!formData.jobTitle.trim()) {
      errors.jobTitle = "Job title is required";
      isValid = false;
    }
    setFormError(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          firstName: formData.fname,
          lastName: formData.lname,
          email: formData.email,
          contactNumber: formData.cnumber,
          password: formData.password,
          jobTitle: formData.jobTitle,
        };
        localStorage.clear();

        const response = await RegisterUser(requestBody);
        //console.log(response.data);
        if (response.data.response_code === 200) {
          CustomToast.success(response.data.message);
          if (response.data.user.jobTitle === "System_Admin") {
            localStorage.setItem("tokenCompany", response.data.token);
            localStorage.setItem("status", response.data.success);
            localStorage.setItem("jobTitle", response.data.user.jobTitle);
            localStorage.setItem("hasCompany", false);
            if (localStorage.getItem("token")) {
              localStorage.removeItem("token");
            }
            setTimeout(() => {
              window.location.href = "/registerCompany";
            }, 2000);
            setLoading(false);
          } else {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("status", response.data.success);
            localStorage.setItem("jobTitle", response.data.user.jobTitle);
            localStorage.setItem("hasCompany", false);
            if (localStorage.getItem("tokenCompany")) {
              localStorage.removeItem("tokenCompany");
            }
            setTimeout(() => {
              window.location.href = "/loginCompany";
            }, 2000);
            setLoading(false);
          }
          setFormData(initialValues);
        } else {
          CustomToast.error(response.data.response_message);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        CustomToast.error(err.response.data.message);
        setLoading(false);
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.centerBox}>
        <Box className={classes.boxLeft}>
          <img
            src={image}
            width={600}
            height={400}
            style={{ position: "absolute", top: "50px", right: "-90px" }}
            alt="login"
          />
          <Typography
            sx={{
              color: "white",
              fontSize: 45,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h1"
          >
            Hello, Friend
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 16,
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="p"
          >
            Enter your details to register
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 20,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h2"
          >
            OR
          </Typography>

          <RouterLink to="/" style={{ textDecoration: "none", width: "50%" }}>
            <Button
              sx={{
                margin: "0.5rem 0",
                backgroundColor: "transparent",
                color: "white",
                width: "100%",
                height: "45px",
                alignSelf: "center",
                border: "1px solid rgba(255,255,255,0.5)",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
            >
              Login
            </Button>
          </RouterLink>
        </Box>
        <Box className={classes.boxRight}>
          <Typography
            sx={{ fontWeight: "600", fontFamily: "'Poppins', sans-serif" }}
            variant="h1"
          >
            Register
          </Typography>
          <Box className={classes.formBox}>
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-name"
            >
              First Name
            </InputLabel>
            <TextField
              error={formError.fname ? true : false}
              id="fname"
              label="Enter your first name"
              variant="outlined"
              value={formData.fname}
              onChange={handleChange}
            />
            {formError.fname && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.fname}
              </Typography>
            )}

            <InputLabel
              sx={{
                mb: 1,
                mt: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-name"
            >
              Last Name
            </InputLabel>
            <TextField
              error={formError.lname ? true : false}
              id="lname"
              label="Enter your last name"
              variant="outlined"
              value={formData.lname}
              onChange={handleChange}
            />
            {formError.lname && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.lname}
              </Typography>
            )}

            <InputLabel
              sx={{
                mb: 1,
                mt: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-email"
            >
              Email
            </InputLabel>
            <TextField
              error={formError.email ? true : false}
              id="email"
              label="Enter your email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
            <InputLabel
              sx={{ fontSize: 12, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-name"
            >
              We’ll never share your email with anyone else
            </InputLabel>
            {formError.email && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.email}
              </Typography>
            )}

            <InputLabel
              sx={{
                mb: 1,
                mt: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-number"
            >
              Contact Number
            </InputLabel>
            <TextField
              error={formError.cnumber ? true : false}
              id="cnumber"
              label="Enter your contact number"
              variant="outlined"
              value={formData.cnumber}
              onChange={handleChange}
            />
            {formError.cnumber && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.cnumber}
              </Typography>
            )}

            <InputLabel
              sx={{
                mb: 1,
                mt: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-password"
            >
              Password
            </InputLabel>
            <FormControl
              sx={{ my: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Enter your password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={handleChange}
                error={formError.password ? true : false}
                value={formData.password}
                label="Enter your password"
              />
            </FormControl>
            {formError.password && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.password}
              </Typography>
            )}

            <InputLabel
              sx={{
                mb: 1,
                mt: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-password"
            >
              Confirm Password
            </InputLabel>

            <FormControl
              sx={{ my: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Enter confirm your password
              </InputLabel>
              <OutlinedInput
                id="cpassword"
                type={showcPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowcPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showcPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={handleChange}
                error={formError.cpassword ? true : false}
                value={formData.cpassword}
                label="Enter confirm your password"
              />
            </FormControl>
            {formError.cpassword && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.cpassword}
              </Typography>
            )}

            <InputLabel
              sx={{ mt: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-password"
            >
              Job Title{" "}
              <span style={{ fontSize: "10px" }}>
                (select and click button)
              </span>
            </InputLabel>
            <DropdownBtn
              formData={formData}
              setFormData={setFormData}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              formError={formError}
              setFormError={setFormError}
            />
            {formError.jobTitle && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.jobTitle}
              </Typography>
            )}
            <Button
              sx={{
                marginTop: "2rem",
                backgroundColor: "#342352",
                color: "white",
                width: "100%",
                height: "40px",
                alignSelf: "center",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "Register"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;

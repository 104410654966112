import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";

const CustomToast = {
  success: (message) => {
    toast.success(message, { className: "custom-toast" });
  },
  error: (message) => {
    toast.error(message, { className: "custom-toast" });
  },
  info: (message) => {
    toast.info(message, { className: "custom-toast" });
  },
  warning: (message) => {
    toast.warning(message, { className: "custom-toast" });
  },
};

export default CustomToast;

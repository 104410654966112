import React, { useState } from "react";
import "./Setting.css";
import { Typography } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import Dropdown from "./Dropdown";
import DeleteModal from "../DeleteModal";
import { jwtDecode } from "jwt-decode";

const MemberCart = ({ item, selectedProjectId, GetAllDevelopersInProject }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteID, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const handleClickRemoveDeveloper = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const getInitials = (name) => {
    const parts = name?.split(" ");
    if (parts.length >= 2) {
      const firstInitial = parts[0]?.charAt(0);
      const lastInitial = parts[1]?.charAt(0);
      return `${firstInitial}${lastInitial}`;
    }
    return "";
  };

  return (
    <div className="memberCart-container">
      <div className="memberCart-left">
        <div className="member-img">
          {item.imageURL ? (
            <img
              src={item.imageURL}
              alt=""
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#abcd",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {getInitials(item.name)}
            </div>
          )}
        </div>

        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            color: "#6A6A6A",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {item.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            color: "#AFAAAA",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          {item.email}
        </Typography>
      </div>
      <div className="memberCart-right">
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            color: "#6A6A6A",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          Role
        </Typography>
        {decodedToken?.jobTitle === "System_Admin" ? (
          <div>
            <Dropdown
              title={"edit"}
              role={item.role}
              userId={item.user_id}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              projectID={selectedProjectId}
              GetAllDevelopersInProject={GetAllDevelopersInProject}
              setValidationErrors={setValidationErrors}
            />
          </div>
        ) : (
          <div>
            <span
              className="dropdown-header"
              style={{ width: "200px", justifyContent: "center" }}
            >
              {item.role}
            </span>
          </div>
        )}
        {decodedToken?.jobTitle === "System_Admin" && (
          <div
            className="r-delete-icon"
            onClick={() => handleClickRemoveDeveloper(item.user_id)}
          >
            <FaTrash />
          </div>
        )}
      </div>
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        title={"project-developer"}
        deleteID={deleteID}
        projectID={selectedProjectId}
        GetAllDevelopersInProject={GetAllDevelopersInProject}
      />
    </div>
  );
};

export default MemberCart;

import React from "react";
import Chart from "../../../components/Progress/Chart";
import Contribution from "../../../components/Progress/Contribution";
import Detail from "../../../components/Progress/Detail";
import "./progress.css"

const Progress = () => {
  return (
    <div className="progress-container">
      <div className="progress-container-right">
        <Chart />
        <Contribution />
      </div>
      <div>
        <Detail />
      </div>
    </div>
  );
};

export default Progress;

import { Modal, Button, Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import CustomToast from "./CustomToast/CustomToast";
import {
  deleteProgressStage,
  deleteCard,
} from "../api/axios";
import { ColumnDataContext } from "./Board/StatusGrid";
const DeleteModal = ({
  show,
  setShow,
  title,
  deleteID,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(!show);
  };
  const { setColumnData } = useContext(ColumnDataContext);
  const handleDelete = async () => {
    setLoading(true);

    if (title === "progressStage") {
      try {
        const res = await deleteProgressStage({ id: deleteID });
        if (res.data.success) {
          CustomToast.success("Progress stage deleted successfully");
          setShow(!show);
          handleClose();
          setColumnData((prev) => {
            return prev.filter((column) => column.id !== deleteID);
          });
        } else {
          CustomToast.error("Delete progress stage failed!");
        }
      } catch (error) {
        console.log("Error delete progress stage", error);
      } finally {
        setLoading(false);
      }
    }
    if (title === "task") {
      try {
        const res = await deleteCard(deleteID.id);
        if (res.data.success) {
          CustomToast.success("Task deleted successfully");
          setShow(!show);
          handleClose();
          setColumnData((prev) => {
            return prev.map((column) => {
              if (column.id === deleteID.progressStage_id) {
                const tasks = column.tasks.filter(
                  (task) => task.id !== deleteID.id
                );
                return { ...column, tasks };
              }
              return column;
            });
          });
        } else {
          CustomToast.error("Task delete failed!");
        }
      } catch (error) {
        console.log("Error delete task", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "24px",
            color: "#6A6A6A",
          }}>
          Confirm Deletion
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "16px",
            mt: "10px",
            color: "#AFAAAA",
            fontWeight: "500",
            textAlign: "center",
          }}>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            gap: "10px",
            mt: "30px",
            width: "100%",
          }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: "#6c757d",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#5a6268",
              },
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{
              backgroundColor: "#dc3545",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#c82333",
              },
            }}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;

import { Modal, Button, Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogOutModal = ({ show, setShow }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(!show);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          border: "none",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "24px",
            color: "#6A6A6A",
          }}
        >
          Confirm Logout
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "16px",
            mt: "10px",
            color: "#AFAAAA",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Are you sure you want to logout
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: "#6c757d",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#5a6268",
              },
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleLogOut}
            sx={{
              backgroundColor: "#dc3545",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#c82333",
              },
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogOutModal;

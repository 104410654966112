import ContributionCart from "./ContributionCart";
import "./Progress.css";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useProject } from "../../context/ProjectContext";
import { getUserContribution } from "../../api/axios";

const Contribution = () => {
  const { selectedProjectId } = useProject();
  const [membertData, setmemberData] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetUserContribution = async (id) => {
    setLoading(true);
    try {
      const res = await getUserContribution(id);
      const transformedResponse = transformApiResponse(res.data);
      setmemberData(transformedResponse.groupedTasksArray);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetUserContribution(selectedProjectId);
    }
  }, [selectedProjectId]);

  const transformApiResponse = (apiResponse) => {
    const totalDoneTaskWeight = apiResponse.result.result.totalDoneTaskWeight;
    const groupedTasksArray = apiResponse.result.result.groupedTasksArray;

    const transformedArray = groupedTasksArray.map((taskGroup) => {
      const user = taskGroup.user;

      let contribution = 0;
      if (totalDoneTaskWeight > 0) {
        contribution = ((taskGroup.doneTasksWight / totalDoneTaskWeight) * 100).toFixed(
          2
        );
      }

      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        imageURL: user.imageURL,
        tasks: taskGroup.tasks,
        taskWight: taskGroup.taskWight,
        doneTasksWight: taskGroup.doneTasksWight,
        totalDoneTaskWeight: totalDoneTaskWeight,
        contribution: contribution,
      };
    });

    return { groupedTasksArray: transformedArray };
  };

  return (
    <div className="contribution-container">
      <div className="contribution-detail">
        <Typography
          variant="h5"
          sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: "600" }}
        >
          Member Contribution
        </Typography>
        <div className="contribution-detail-table">
          {loading ? (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              Loading...
            </span>
          ) : membertData.length > 0 ? (
            membertData.map((item) => (
              <ContributionCart key={item.id} item={item} />
            ))
          ) : (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              No member details!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contribution;

import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { ColumnDataContext } from "./StatusGrid";
import {
  getAllTasksOfProject,
  getUsersOfProject,
  updateTask,
  getTaskById,
} from "./../../api/axios";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import "./Board.css";
import TaskAttachmentView from "./TaskAttachmentView";
import { useProject } from "../../context/ProjectContext";
import CustomToast from "../CustomToast/CustomToast";

const IssueEditModal = ({ show, setShow, issue }) => {
  const [formData, setFormData] = useState({
    taskName: "",
    flag: "",
    assignee: "",
    reporter: "",
    startDate: null,
    endDate: null,
    link: "",
    description: "",
    file: [],
    newAttachments: [],
  });
    const { selectedProjectId } = useProject();
  const [allTasksOfProject, setAllTasksOfProject] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const { setColumnData } = useContext(ColumnDataContext);
  const [usersOfTheProject, setUsersOfTheProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getTaskData = async () => {
    try {
      const taskData = await getTaskById(issue.id);
      if (taskData.data.result.success) {
        setFormData({
          id: taskData.data.result.result.id,
          taskName: taskData.data.result.result.name,
          flag: taskData.data.result.result.flag,
          assignee: taskData.data.result.result.assign,
          reporter: taskData.data.result.result.reporter,
          startDate: formatDate(taskData.data.result.result.startDate),
          endDate: formatDate(taskData.data.result.result.endDate),
          link: taskData.data.result.result.linked_task_id
            ? taskData.data.result.result.linked_task_id
            : "",
          description: taskData.data.result.result.description,
          file: taskData.data.result.result.Files,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      getTaskData();
    }
  }, [show]);

  const getAllTasksOfProjectHandler = async () => {
    try {
      const response = await getAllTasksOfProject(selectedProjectId);
      if (response.data.result.success) {
        setAllTasksOfProject(response.data.result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersOfProjectHandler = async () => {
    try {
      const response = await getUsersOfProject(selectedProjectId);
      if (response.data.success) {
        setUsersOfTheProject(response.data.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      getAllTasksOfProjectHandler();
      getUsersOfProjectHandler();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const validationSchema = yup.object().shape({
    taskName: yup.string().required("Task Name is required"),
    flag: yup.string().required("Flag is required"),
    assignee: yup.string().required("Assignee is required"),
    reporter: yup.string().required("Reporter is required"),
    startDate: yup
      .date()
      .nullable()
      .required("Start Date is required"),
    endDate: yup.date().nullable().required("End Date is required"),
    link: yup.string(),
    description: yup.string(),
    newAttachments: yup.mixed(),
  });

  const validateField = (field, value) => {
    if (
      field === "endDate" &&
      formData.startDate !== null &&
      value < formData.startDate
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "The end date must be later than the start date",
      }));
      return;
    }
    validationSchema
      .validateAt(field, { ...formData, [field]: value })
      .then(() => {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [field]: error.message,
        }));
      });
  };

  const handleFieldChange = (field, value) => {
    if (field === "newAttachments") {
      const filesArray = Array.from(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: filesArray,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value,
      }));
    }
    validateField(field, value);
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (errors) {
      const formattedErrors = {};
      errors.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });
      setValidationErrors(formattedErrors);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (await validateForm()) {
      if (formData.startDate > formData.endDate) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "The end date must be later than the start date",
        }));
        return;
      }

      let taskIdex = 0;
      const taskFormData = new FormData();
      taskFormData.append("id", issue.id);
      taskFormData.append("name", formData.taskName);
      taskFormData.append("flag", formData.flag);
      taskFormData.append("startDate", formData.startDate);
      taskFormData.append("endDate", formData.endDate);
      taskFormData.append("description", formData.description);
      taskFormData.append("taskIndex", taskIdex);
      taskFormData.append("assign", formData.assignee);
      taskFormData.append("reporter", formData.reporter);
      if (formData.link) {
        taskFormData.append("linked_task_id", formData.link);
      }
      if (formData.newAttachments) {
        formData.newAttachments.forEach((file) => {
          taskFormData.append("attachment", file);
        });
      }

      try {
        setIsLoading(true);
        const response = await updateTask(taskFormData);
        if (response.data.result.status) {
          setFormData({
            taskName: "",
            flag: "",
            assignee: "",
            reporter: "",
            startDate: null,
            endDate: null,
            link: "",
            description: "",
            file: [],
            newAttachments: [],
          });
          setColumnData((prev) =>
            prev.map((column) => {
              if (column.id === issue.progressStage_id) {
                return {
                  ...column,
                  tasks: column.tasks.map((task) => {
                    if (task.id === issue.id) {
                      return response.data.result.result;
                    }
                    return task;
                  }),
                };
              }
              return column;
            })
          );
          setShow(false);
          CustomToast.success("Issue update successfull!")
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}>
              Edit Task
            </Typography>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name">
                    Task Name
                  </InputLabel>
                  <TextField
                    id="taskName"
                    name="taskName"
                    type="text"
                    label="Enter a task name"
                    variant="outlined"
                    value={formData.taskName}
                    onChange={(e) =>
                      handleFieldChange("taskName", e.target.value)
                    }
                    error={!!validationErrors.taskName}
                  />
                  {validationErrors.taskName && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.taskName}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}>
                    Select a flag
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select a flag</InputLabel>
                    <Select
                      id="flag"
                      value={formData.flag}
                      label="Select a flag"
                      onChange={(e) =>
                        handleFieldChange("flag", e.target.value)
                      }
                      error={!!validationErrors.flag}>
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="hard">Hard</MenuItem>
                    </Select>
                    {validationErrors.flag && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}>
                        {validationErrors.flag}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}>
                    Assign
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="assignee"
                      label="Select an Option"
                      value={formData.assignee}
                      onChange={(e) =>
                        handleFieldChange("assignee", e.target.value)
                      }
                      error={!!validationErrors.assignee}>
                      {usersOfTheProject.map((user, index) => (
                        <MenuItem key={index} value={user.user_id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.assignee && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.assignee}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}>
                    Reporter
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="reporter"
                      value={formData.reporter}
                      label="Select an Option"
                      onChange={(e) =>
                        handleFieldChange("reporter", e.target.value)
                      }
                      error={!!validationErrors.reporter}>
                      {usersOfTheProject.map((user, index) => (
                        <MenuItem key={index} value={user.user_id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.reporter && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}>
                        {validationErrors.reporter}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name">
                    Start Date
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="startDate"
                    name="startDate"
                    label=" "
                    type="date"
                    variant="outlined"
                    value={formData.startDate}
                    onChange={(e) =>
                      handleFieldChange("startDate", e.target.value)
                    }
                    error={!!validationErrors.startDate}
                  />
                  {validationErrors.startDate && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.startDate}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name">
                    End Date
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="endDate"
                    label=" "
                    name="endDate"
                    type="date"
                    variant="outlined"
                    value={formData.endDate}
                    onChange={(e) =>
                      handleFieldChange("endDate", e.target.value)
                    }
                    error={!!validationErrors.endDate}
                  />
                  {validationErrors.endDate && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.endDate}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "10px",
                }}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}>
                    Link
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="link"
                      value={formData.link}
                      label="Select an Option"
                      onChange={(e) =>
                        handleFieldChange("link", e.target.value)
                      }
                      error={!!validationErrors.link}>
                      {allTasksOfProject.map((task) => (
                        <MenuItem key={task.id} value={task.id}>
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.link && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}>
                        {validationErrors.link}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name">
                    Description
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    type="text"
                    label="Enter Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                    error={!!validationErrors.description}
                  />
                  {validationErrors.description && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.description}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name">
                    Upload Attachment
                  </InputLabel>
                  <TextField
                    fullWidth
                    label=" "
                    name="newAttachments"
                    inputProps={{
                      multiple: true,
                      accept: "image/*,video/*,application/pdf,application/zip",
                    }}
                    onChange={(e) =>
                      handleFieldChange("newAttachments", e.currentTarget.files)
                    }
                    variant="outlined"
                    type="file"
                    error={!!validationErrors.newAttachments}
                  />
                  {validationErrors.newAttachments && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.newAttachments}
                    </FormHelperText>
                  )}
                </Box>
                {formData.file && formData.file.length > 0 && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <Typography
                      style={{
                        fontSize: "15px",
                        fontFamily: "'Poppins', sans-serif",

                        color: "#666666",
                      }}>
                      Attachments:
                    </Typography>
                    {/* <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}>
                Files shows here
              </Typography> */}
                    {formData.file &&
                      formData.file.map((file, index) => (
                        <div key={index}>
                          <TaskAttachmentView
                            task={formData}
                            setFormData={setFormData}
                            showDeleteButton={true}
                            file={file}
                          />
                        </div>
                      ))}
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="center" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    width: "80%",
                    height: "40px",
                    fontSize: "16px",
                    borderRadius: "50px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}>
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IssueEditModal;

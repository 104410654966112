import React, { useState, useEffect } from "react";
import "./Setting.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { updateProjectDeveloperRole } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";

const Dropdown = ({
  role,
  setSelectedOption,
  selectedOption,
  setValidationErrors,
  title,
  userId,
  projectID,
  GetAllDevelopersInProject,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setValidationErrors({});

    if (title === "edit") {
      try {
        const reqBody = {
          userId: userId,
          projectId: projectID,
          newRole: option.value,
        };
        const res = await updateProjectDeveloperRole(reqBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          GetAllDevelopersInProject(projectID);
        } else {
          CustomToast.error("Role update failed!");
        }
      } catch (error) {
        console.log("Error role update", error);
        CustomToast.error(error.response.data.message);
      }
    }
  };

  let options = [
    { value: "System_Admin", label: "SYSTEM ADMIN" },
    { value: "QA_Engineer", label: "QA ENGINEER" },
    { value: "Software_Engineer", label: "SOFTWARE ENGINEER" },
    { value: "Business_Analyst", label: "BUSINESS ANALYST" },
    { value: "Project_Manager", label: "PROJECT MANAGER" },
    { value: "UI/Ux_Engineer", label: "UX UI ENGINEER" },
  ];

  if (title === "add") {
    options = options.filter((option) => option.value !== "System_Admin");
  }
  if (role === "System_Admin") {
    options = options.filter((option) => option.value === "System_Admin");
  }

  useEffect(() => {
    const initialSelectedOption = options.find(
      (option) => option.value === role
    );
    setSelectedOption(initialSelectedOption);
  }, [role]);

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : "Select an option"}
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}>
          <KeyboardArrowDownIcon />
        </span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option) => (
            <li
              key={option.value}
              className={`dropdown-item ${
                option.value === "System_Admin" ? "disabled" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Link,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import image from "../../../assets/images/login.png";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { LoginAPI } from "../../../api/axios";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { jwtDecode } from "jwt-decode";
import { Spinner } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#F1EAFF",
    padding: "2rem 15rem",

    [theme.breakpoints.down("lg")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  centerBox: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "red",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  boxLeft: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    backgroundColor: "white",
    padding: "6rem 4rem",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "2rem",
  },
  boxRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "50%",
    paddingTop: "20%",
    background:
      "linear-gradient(180deg, rgba(35, 21, 65, 1) 0%, rgba(226, 178, 255, 1) 180%)",
    overflow: "hidden",
  },
}));
const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validateForm = () => {
    const errors = { email: "", password: "" };
    let state = true;
    if (formData.email === "") {
      errors.email = "Email is required";
      state = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      state = false;
    }
    if (formData.password === "") {
      errors.password = "Password is required";
      state = false;
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number";
      state = false;
    } else {
      state = true;
    }
    setFormError({
      ...errors,
    });
    return state;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          email: formData.email,
          password: formData.password,
        };

        const response = await LoginAPI(requestBody);
        //console.log(response);
        if (response.data.response_code === 200) {
          const token = response.data.token;
          const decodedToken = jwtDecode(token);
          if (decodedToken.jobTitle === "System_Admin") {
            localStorage.setItem("tokenCompany", response.data.token);
            localStorage.setItem("status", response.data.success);
            localStorage.setItem("jobTitle", decodedToken.jobTitle);
            localStorage.setItem("hasCompany", decodedToken.hasCompany);
            if (localStorage.getItem("token")) {
              localStorage.removeItem("token");
            }
            CustomToast.success("Login Success");
            if (decodedToken.hasCompany === true) {
              setTimeout(() => {
                window.location.href = "/Company";
              }, 2000);
              setLoading(false);
            } else {
              setTimeout(() => {
                window.location.href = "/registerCompany";
              }, 2000);
              setLoading(false);
            }
          } else {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("status", response.data.success);
            localStorage.setItem("jobTitle", decodedToken.jobTitle);
            localStorage.setItem("hasCompany", decodedToken.hasCompany);
            if (localStorage.getItem("tokenCompany")) {
              localStorage.removeItem("tokenCompany");
            }
            CustomToast.success("Login Success");
            if (decodedToken.hasCompany === true) {
              setTimeout(() => {
                window.location.href = "/Company";
              }, 2000);
              setLoading(false);
            } else {
              setTimeout(() => {
                window.location.href = "/loginCompany";
              }, 2000);
              setLoading(false);
            }
          }
        }
      } catch (err) {
        console.log(err);
        CustomToast.error(err.response.data.message);
        setLoading(false);
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.centerBox}>
        <Box className={classes.boxLeft}>
          <Typography
            sx={{ fontWeight: "600", fontFamily: "'Poppins', sans-serif" }}
            variant="h1"
          >
            Login
          </Typography>
          <Box className={classes.formBox}>
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-email"
            >
              Email
            </InputLabel>
            <TextField
              error={formError.email && true}
              id="email"
              label="Enter your email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
            {formError.email && (
              <Typography sx={{ color: "red", fontSize: 12 }}>
                {formError.email}
              </Typography>
            )}
            <InputLabel
              sx={{ my: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-password"
            >
              Password
            </InputLabel>
            <FormControl
              sx={{ my: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Enter your password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={handleChange}
                error={formError.password ? true : false}
                value={formData.password}
                label="Enter your password"
              />
            </FormControl>
            {formError.password && (
              <Typography sx={{ color: "red", fontSize: 12 }}>
                {formError.password}
              </Typography>
            )}
            <Link
              href="/forgotpassword"
              sx={{
                color: "blue",
                marginTop: 2,
                textDecoration: "none",
                textAlign: "right",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Forgot Password ?
            </Link>

            <Button
              sx={{
                marginTop: "2rem",
                backgroundColor: "#342352",
                color: "white",
                width: "100%",
                height: "40px",
                alignSelf: "center",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                borderRadius: "50px",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "Login"}
            </Button>
          </Box>
        </Box>
        <Box className={classes.boxRight}>
          <img
            src={image}
            width={550}
            height={430}
            style={{ position: "absolute", top: "-70px" }}
            alt="login"
          />

          <Typography
            sx={{
              color: "white",
              fontSize: 45,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h1"
          >
            Welcome Back
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 16,
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="p"
          >
            Enter your credential to login
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 20,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h2"
          >
            OR
          </Typography>

          <RouterLink
            to="/register"
            style={{ textDecoration: "none", width: "50%" }}
          >
            <Button
              sx={{
                margin: "0.5rem 0",
                backgroundColor: "transparent",
                color: "white",
                width: "100%",
                height: "45px",
                alignSelf: "center",
                border: "1px solid rgba(255,255,255,0.5)",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
            >
              Register
            </Button>
          </RouterLink>
          {/* <Typography
            sx={{
              color: "white",
              fontSize: 20,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h2"
          >
            OR
          </Typography>
          <RouterLink
            to="/loginCompany"
            style={{ textDecoration: "none", width: "50%" }}
          >
            <Button
              sx={{
                marginTop: "1rem",
                backgroundColor: "transparent",
                color: "white",
                width: "100%",
                height: "45px",
                border: "1px solid rgba(255,255,255,0.5)",
                alignSelf: "center",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
            >
              Login as Company
            </Button>
          </RouterLink> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

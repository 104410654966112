import React, { createContext, useState, useContext, useEffect } from "react";

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [memberID, setMemberID] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(() => {
    const savedProjectId = localStorage.getItem("selectedProjectId");
    return savedProjectId ? JSON.parse(savedProjectId) : null;
  });

  const selectProject = (projectId) => {
    setSelectedProjectId(projectId);
    localStorage.setItem("selectedProjectId", JSON.stringify(projectId));
  };

  useEffect(() => {
    localStorage.setItem("selectedProjectId", JSON.stringify(selectedProjectId));
  }, [selectedProjectId]);

  return (
    <ProjectContext.Provider value={{ selectedProjectId, selectProject,memberID,setMemberID }}>
      {children}
    </ProjectContext.Provider>
  );
};
import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { SendOTP, changePassword, confirmEmail } from "../../../api/axios";
import CustomToast from "../../../components/CustomToast/CustomToast";
//import { set } from "date-fns";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#F1EAFF",
    padding: "2rem 15rem",
    [theme.breakpoints.down("lg")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  centerBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "50%",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    alignItems: "center",
    backgroundColor: "white",
    paddingTop: "1.5rem",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));
const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ email: "" });
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validateForm = () => {
    const errors = { email: "" };
    let state = true;
    if (email === "") {
      errors.email = "Email is required";
      state = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
      state = false;
    }
    if (step === 2 && otp === "") {
      errors.otp = "OTP is required";
      state = false;
    }
    if (step === 3 && newPassword === "") {
      errors.newPassword = "New Password is required";
      state = false;
    } else if (
      step === 3 &&
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(newPassword)
    ) {
      errors.newPassword =
        "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number";
      state = false;
    }
    setError(errors);
    return state;
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          email: email,
        };
        const response = await SendOTP(requestBody);
        if (response.status) {
          setTimeout(() => {
            setStep((prev) => prev + 1);
            setLoading(false);
          }, 2000);
          CustomToast.success(response.data.message);
        } else {
          console.log(response.data);
          CustomToast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        CustomToast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };
  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          email: email,
          enteredOTP: otp,
        };
        const response = await confirmEmail(requestBody);
        if (response.status) {
          setTimeout(() => {
            setStep((prev) => prev + 1);
            setLoading(false);
          }, 2000);
          CustomToast.success(response.data.message);
        } else {
          console.log(response.data);
          CustomToast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        CustomToast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          email: email,
          newPassword: newPassword,
        };
        const response = await changePassword(requestBody);
        if (response.status) {
          setTimeout(() => {
            window.location.href = "/";
            setLoading(false);
          }, 2000);
          CustomToast.success(response.data.message);
        } else {
          console.log(response.data);
          CustomToast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        CustomToast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };
  return (
    <>
      {step === 1 && (
        <Box className={classes.root}>
          <Box className={classes.centerBox}>
            <h1>Reset Password</h1>
            <Box className={classes.formBox}>
              <FormControl className={classes.formBox}>
                <TextField
                  sx={{ width: "80%" }}
                  label="Enter your Email Address"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError({ email: "" });
                  }}
                  required
                  error={error.email ? true : false}
                />
                {error.email && (
                  <Typography
                    sx={{ color: "red", fontSize: 12, textAlign: "left" }}
                  >
                    {error.email}
                  </Typography>
                )}
                <Button
                  sx={{
                    marginTop: "2rem",
                    backgroundColor: "black",
                    color: "white",
                    width: "200px",
                    height: "auto",
                    alignSelf: "center",
                    fontSize: "1rem",
                    borderRadius: "50px",
                    padding: "0.5rem 1rem",
                    mt: 2,
                    textTransform: "capitalize",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "purple",
                    },
                  }}
                  onClick={handleSubmitEmail}
                >
                  {loading ? <Spinner /> : "Send OTP"}
                </Button>
                <RouterLink sx={{ textDecoration: "none" }} to="/">
                  <Button
                    sx={{
                      marginTop: "2rem",
                      backgroundColor: "black",
                      color: "white",
                      width: "200px",
                      height: "auto",
                      alignSelf: "center",
                      fontSize: "1rem",
                      borderRadius: "50px",
                      padding: "0.5rem 1rem",
                      mt: 2,
                      textTransform: "capitalize",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "purple",
                      },
                    }}
                  >
                    Back to Login
                  </Button>
                </RouterLink>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box className={classes.root}>
          <Box className={classes.centerBox}>
            <h1>Enter OTP</h1>
            <Box className={classes.formBox}>
              <FormControl className={classes.formBox}>
                <TextField
                  sx={{ width: "80%" }}
                  label="Enter your OTP"
                  type="email"
                  variant="outlined"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setError({ otp: "" });
                  }}
                  required
                  error={error.otp ? true : false}
                />
                {error.otp && (
                  <Typography
                    sx={{ color: "red", fontSize: 12, textAlign: "left" }}
                  >
                    {error.otp}
                  </Typography>
                )}
                <Button
                  sx={{
                    marginTop: "2rem",
                    backgroundColor: "black",
                    color: "white",
                    width: "200px",
                    height: "auto",
                    alignSelf: "center",
                    fontSize: "1rem",
                    borderRadius: "50px",
                    padding: "0.5rem 1rem",
                    mt: 2,
                    textTransform: "capitalize",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "purple",
                    },
                  }}
                  onClick={handleSubmitOTP}
                >
                  {loading ? <Spinner /> : "Submit"}
                </Button>
                <RouterLink sx={{ textDecoration: "none" }} to="/">
                  <Button
                    sx={{
                      marginTop: "2rem",
                      backgroundColor: "black",
                      color: "white",
                      width: "200px",
                      height: "auto",
                      alignSelf: "center",
                      fontSize: "1rem",
                      borderRadius: "50px",
                      padding: "0.5rem 1rem",
                      mt: 2,
                      textTransform: "capitalize",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "purple",
                      },
                    }}
                  >
                    Back to Login
                  </Button>
                </RouterLink>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}
      {step === 3 && (
        <Box className={classes.root}>
          <Box className={classes.centerBox}>
            <h1>Enter New Password</h1>
            <Box className={classes.formBox}>
              <FormControl className={classes.formBox}>
                <FormControl
                  sx={{
                    my: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                    width: "80%",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Enter your password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setError({ newPassword: "" });
                    }}
                    error={error.newPassword ? true : false}
                    value={newPassword}
                    label="Enter New Password"
                  />
                </FormControl>
                {error.newPassword && (
                  <Typography
                    sx={{ color: "red", fontSize: 12, textAlign: "left" }}
                  >
                    {error.newPassword}
                  </Typography>
                )}

                <Button
                  sx={{
                    marginTop: "2rem",
                    backgroundColor: "black",
                    color: "white",
                    width: "200px",
                    height: "auto",
                    alignSelf: "center",
                    fontSize: "1rem",
                    borderRadius: "50px",
                    padding: "0.5rem 1rem",
                    textTransform: "capitalize",
                    mt: 1,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "purple",
                    },
                  }}
                  onClick={handleSubmitNewPassword}
                >
                  {loading ? <Spinner /> : "Submit"}
                </Button>
                <RouterLink sx={{ textDecoration: "none" }} to="/">
                  <Button
                    sx={{
                      marginTop: "2rem",
                      backgroundColor: "black",
                      color: "white",
                      width: "200px",
                      height: "auto",
                      alignSelf: "center",
                      fontSize: "1rem",
                      borderRadius: "50px",
                      padding: "0.5rem 1rem",
                      mt: 2,
                      textTransform: "capitalize",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "purple",
                      },
                    }}
                  >
                    Back to Login
                  </Button>
                </RouterLink>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ForgotPassword;

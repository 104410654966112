import "./Board.css";
import { Draggable } from "react-beautiful-dnd";
import React, { useState } from "react";
import ViewIssueModal from "./ViewIssueModal";

const truncateString = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  }
  return string;
};

const IssueCart = ({ issue, index }) => {
  const [show, setShow] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState("");
  const handleClickViewIssue = (issue) => {
    setShow(true);
    setSelectedIssue(issue);
  };

  return (
    <Draggable
      draggableId={issue.id.toString()}
      index={index}
      isDragDisabled={false}
    >
      {(draggableProvided) => (
        <>
          <div
            className="issue-cart"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            onClick={() => handleClickViewIssue(issue)}
          >
            <span className="issue-title" title={issue.name}>
              {truncateString(issue.name, 15)}
            </span>
            <span className="issue-description" title={issue.description}>
              {truncateString(issue.description, 80)}
            </span>

            <div className="assignee-img">
              {issue?.assignedUser?.imageURL !== null ? (
                <img
                  src={
                    issue?.assignedUser?.imageURL !== null
                      ? issue?.assignedUser?.imageURL
                      : ""
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: "#abcd",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >{`${issue.assignedUser.firstName.charAt(
                  0
                )}${issue.assignedUser.lastName.charAt(0)}`}</div>
              )}
            </div>
          </div>
          <ViewIssueModal
            show={show}
            setShow={setShow}
            taskId={selectedIssue.id}
          />
        </>
      )}
    </Draggable>
  );
};

export default IssueCart;

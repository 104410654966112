import React, { useState, useEffect } from "react";
import "./Setting.css";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { useProject } from "../../context/ProjectContext";
import { GetProjectByID, formatDate, updateProject } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";
import DeleteModal from "../DeleteModal";
import { jwtDecode } from "jwt-decode";

const ProjectSetting = () => {
  const initialState = {
    projectName: "",
    description: "",
    startDate: "",
    endDate: "",
  };
  const [projectDetails, setProjectDetails] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { selectedProjectId } = useProject();
  const [deleteID, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [minEndDate, setMinEndDate] = useState("");
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const getProjectById = async (id) => {
    try {
      const data = await GetProjectByID(id);
      setProjectDetails({
        projectName: data.data.project.projectName,
        description: data.data.project.description,
        startDate: formatDate(data.data.project.startDate),
        endDate: data.data.project.endDate,
      });
      setMinEndDate(formatDate(data.data.project.startDate));
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      getProjectById(selectedProjectId);
    }
  }, [selectedProjectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setProjectDetails({ ...projectDetails, [name]: value });

    if (name === "startDate") {
      setMinEndDate(value);
      if (new Date(projectDetails.endDate) < new Date(value)) {
        setProjectDetails({ ...projectDetails, endDate: "" });
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!projectDetails.projectName.trim()) {
      errors.projectName = "Project name is required";
    }
    if (!projectDetails.description.trim()) {
      errors.description = "Description is required";
    }
    if (!projectDetails.startDate.trim()) {
      errors.startDate = "Start date is required";
    }
    if (!projectDetails.endDate.trim()) {
      errors.endDate = "End date is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const updateReqBody = {
          projectName: projectDetails.projectName,
          description: projectDetails.description,
          startDate: projectDetails.startDate,
          endDate: projectDetails.endDate,
        };
        const res = await updateProject(selectedProjectId, updateReqBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          getProjectById(selectedProjectId);
          window.location.reload();
        } else {
          CustomToast.error("Project update failed!");
        }
      } catch (error) {
        console.log("Error update project", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickDeleteProject = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  return (
    <div className="project-setting-container">
      <Typography
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "600",
          fontSize: "24px",
          color: "#342352"
        }}
      >
        Basic Settings
      </Typography>
      <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <InputLabel
            sx={{
              mb: 1,
              fontSize: 15,
              fontFamily: "'Poppins', sans-serif",
            }}
            htmlFor="outlined-adornment-name"
          >
            Project Name
          </InputLabel>
          <TextField
            fullWidth
            id="projectName"
            name="projectName"
            type="text"
            variant="filled"
            value={projectDetails.projectName}
            inputProps={{
              readOnly: decodedToken?.jobTitle !== "System_Admin",
            }}
            onChange={handleInputChange}
            error={!!validationErrors.projectName}
          />
          {validationErrors.projectName && (
            <FormHelperText style={{ color: "#f44336" }}>
              {validationErrors.projectName}
            </FormHelperText>
          )}
        </Box>
        <Box>
          <InputLabel
            sx={{
              mb: 1,
              fontSize: 15,
              fontFamily: "'Poppins', sans-serif",
            }}
            htmlFor="outlined-adornment-name"
          >
            Description
          </InputLabel>
          <TextField
            fullWidth
            id="description"
            name="description"
            type="text"
            variant="filled"
            multiline
            rows={4}
            value={projectDetails.description}
            inputProps={{
              readOnly: decodedToken?.jobTitle !== "System_Admin",
            }}
            onChange={handleInputChange}
            error={!!validationErrors.description}
          />
          {validationErrors.description && (
            <FormHelperText style={{ color: "#f44336" }}>
              {validationErrors.description}
            </FormHelperText>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
          }}
        >
          <Box>
            <InputLabel
              sx={{
                mb: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-name"
            >
              Start Date
            </InputLabel>
            <TextField
              fullWidth
              id="startDate"
              name="startDate"
              type="date"
              variant="filled"
              value={projectDetails.startDate}
              inputProps={{
                readOnly: decodedToken?.jobTitle !== "System_Admin",
              }}
              onChange={handleInputChange}
              error={!!validationErrors.startDate}
            />
            {validationErrors.startDate && (
              <FormHelperText style={{ color: "#f44336" }}>
                {validationErrors.startDate}
              </FormHelperText>
            )}
          </Box>
          <Box>
            <InputLabel
              sx={{
                mb: 1,
                fontSize: 15,
                fontFamily: "'Poppins', sans-serif",
              }}
              htmlFor="outlined-adornment-name"
            >
              End Date
            </InputLabel>
            <TextField
              fullWidth
              id="endDate"
              name="endDate"
              type="date"
              variant="filled"
              value={projectDetails.endDate}
              inputProps={{
                min: minEndDate,
                readOnly: decodedToken?.jobTitle !== "System_Admin",
              }}
              onChange={handleInputChange}
              error={!!validationErrors.endDate}
            />
            {validationErrors.endDate && (
              <FormHelperText style={{ color: "#f44336" }}>
                {validationErrors.endDate}
              </FormHelperText>
            )}
          </Box>
        </Box>
        {decodedToken?.jobTitle === "System_Admin" && (
          <Box display="flex" justifyContent="right" mt="20px" gap="10px">
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#231541",
                color: "white",
                padding: "8px 40px",
                fontSize: "16px",
                borderRadius: "8px",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "purple",
                },
              }}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => handleClickDeleteProject(selectedProjectId)}
              sx={{
                backgroundColor: "#E34545",
                color: "white",
                padding: "8px 40px",
                fontSize: "16px",
                borderRadius: "8px",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "purple",
                },
              }}
            >
              Delete Project
            </Button>
          </Box>
        )}
      </Box>
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        title={"project"}
        deleteID={deleteID}
      />
    </div>
  );
};

export default ProjectSetting;

import { Modal, Button, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "./Setting.css";
import { addDeveloprToProject } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";
import Dropdown from "./Dropdown";

const MemberAddModal = ({
  show,
  setShow,
  item,
  projectId,
  GetAllDevelopersInProject,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!selectedOption) {
      errors.role = "Role is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const requestBody = {
          userId: item.id,
          projectId: projectId,
          role: selectedOption.value,
        };

        const res = await addDeveloprToProject(requestBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          GetAllDevelopersInProject(projectId);
          handleClose();
        } else {
          CustomToast.error("Developer add failed!");
        }
      } catch (error) {
        console.log("Error add developer", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
            }}
          >
            Add Member To Project
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "100%",
            }}
          >
            <Box>
              <div className="memberCart-container">
                <div className="memberCart-left">
                  <div className="member-img-add">
                    {item.image ? (
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#abcd",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >{`${item.firstName?.charAt(0)}${item.lastName?.charAt(
                        0
                      )}`}</div>
                    )}
                  </div>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: "#6A6A6A",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      {item.firstName} {item.lastName}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: "#AFAAAA",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      {item.email}
                    </Typography>
                  </Box>
                </div>
                <div className="memberCart-right">
                  <Typography
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: "#6A6A6A",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Role
                  </Typography>
                  <div>
                    <Dropdown
                      title={"add"}
                      role={""}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      setValidationErrors={setValidationErrors}
                    />
                  </div>
                </div>
              </div>
            </Box>
            {validationErrors.role && (
              <Typography
                sx={{
                  fontFamily: "'Poppins', sans-serif",
                  color: "#f44336",
                  fontWeight: "500",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {validationErrors.role}
              </Typography>
            )}

            <Box display="flex" justifyContent="right" mt="20px" gap="10px">
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#c82333",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#231541",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "purple",
                  },
                }}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MemberAddModal;

import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  GetProjectByID,
  getCompanyDetails,
  getUserDetails,
  getNotificationOfUser,
  seenUserNotifications,
} from "../../api/axios";
import { useProject } from "../../context/ProjectContext";
import { jwtDecode } from "jwt-decode";
import { tokens } from "../../theme";
import "./TopBar.css";
import LogOutModal from "./LogOutModal";
import { Brightness1, DoneAllTwoTone, DoneTwoTone } from "@mui/icons-material";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { selectedProjectId } = useProject();
  const [project, setProject] = useState({});
  const [company, setCompany] = useState({});
  const [user, setUser] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logOutShow, setLogOutShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const menuRef = useRef(null);
  const notificationRef = useRef(null);
  const avatarRef = useRef(null);
  const bellRef = useRef(null);

  const handleLogout = () => {
    setLogOutShow(true);
    setIsMenuOpen(false);
  };

  const handleEditProfile = () => {
    setIsMenuOpen(false);
    window.location.href = "/profile";
  };

  const GetCompanyDetails = async () => {
    try {
      const res = await getCompanyDetails();
      setCompany(res.data.userCompany);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  useEffect(() => {
    GetCompanyDetails();
  }, []);

  const getNotifications = async () => {
    try {
      const response = await getNotificationOfUser();
      if (response.data.result.status) {
        setNotificationList(response.data.result.result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (notificationShow) {
      clearHandler();
    } else {
      setNotificationList([]);
    }
  }, [notificationShow]);

  const getProjectById = async (projectId) => {
    try {
      const response = await GetProjectByID(projectId);
      setProject(response.data.project);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      getProjectById(selectedProjectId);
    }
  }, [selectedProjectId]);

  const GetUserDetails = async (id) => {
    try {
      const res = await getUserDetails(id);
      setUser(res.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    GetUserDetails(decodedToken.userId);
  }, [decodedToken.userId]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !avatarRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideNotification = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        !bellRef.current.contains(event.target)
      ) {
        setNotificationShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideNotification);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNotification);
    };
  }, []);

  const clearHandler = async () => {
    try {
      await seenUserNotifications();
    } catch (error) {
      console.log("🚀 ~ clearHandler ~ error:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="15px 30px"
      backgroundColor="white"
      height="15vh">
      <Box display="flex" borderRadius="3px">
        <Typography variant="h2" color={colors.grey[500]} fontWeight="bold">
          {selectedProjectId ? project.projectName : company.companyname}
        </Typography>
      </Box>

      <Box display="flex" gap="5px" position="relative">
        <IconButton sx={{ position: "relative" }}>
          {notificationList.length > 0 && (
            <div style={{ position: "absolute", top: "10px", right: "2px" }}>
              <div
                style={{
                  backgroundColor: "red",
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <div
                  style={{
                    color: "white",
                    fontSize: "9px",
                    fontWeight: "bold",
                  }}>
                  {notificationList.length}
                </div>
              </div>{" "}
            </div>
          )}
          <NotificationsOutlinedIcon
            ref={bellRef}
            onClick={() => {
              setNotificationShow(!notificationShow);
            }}
            style={{ fontSize: "1.8rem" }}
          />
        </IconButton>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="2px solid #f2f0f0"
          borderRadius="50%"
          width="60px"
          height="60px"
          position="relative"
          overflow="hidden"
          onClick={toggleMenu}
          ref={avatarRef}>
          {user.imageURL ? (
            <img
              alt="profile-user"
              width="100%"
              height="auto"
              src={user.imageURL}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#abcd",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}>{`${user.firstName?.charAt(0)}${user.lastName?.charAt(
              0
            )}`}</div>
          )}
        </Box>
        {isMenuOpen && (
          <Box
            ref={menuRef}
            sx={{
              position: "absolute",
              top: "65px",
              right: "0px",
              width: "300px",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "30px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              zIndex: 1000,
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="2px solid #f2f0f0"
                borderRadius="50%"
                width="60px"
                height="60px"
                position="relative"
                overflow="hidden">
                {user.imageURL ? (
                  <img
                    alt="profile-user"
                    src={user.imageURL}
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#abcd",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>{`${user.firstName?.charAt(0)}${user.lastName?.charAt(
                    0
                  )}`}</div>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#6A6A6A",
                    display: "flex",
                    gap: "5px",
                  }}>
                  <span>{user.firstName}</span>
                  <span>{user.lastName}</span>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#B982FF",
                  }}>
                  {user.email}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <div onClick={handleEditProfile} className="edit-profile-button">
                Profile
              </div>
              <div onClick={handleLogout} className="logout-button">
                Logout
              </div>
            </Box>
          </Box>
        )}

        {notificationShow && (
          <Box
            ref={notificationRef}
            sx={{
              position: "absolute",
              top: "65px",
              right: "60px",
              width: "450px",
              maxHeight: "400px",
              backgroundColor: "white",
              overflow: "auto",
              borderRadius: "5px",
              padding: "20px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              zIndex: 1000,
            }}>
            <div>
              {" "}
              <div
                style={{ fontSize: "20px", borderBottom: "2px solid #aaa" }}
                className=" text-center fw-bold mb-2">
                Notifications
              </div>
              {notificationList.map((notification, index) => {
                return (
                  <div key={index} className=" d-flex pb-2  gap-2">
                    <div className=" pb-2">
                      <Brightness1
                        sx={{ fontSize: "14px", color: "#8872a7" }}
                      />
                    </div>
                    <div
                      className="pb-2"
                      style={{
                        borderBottom: "1px solid #aaa",
                        width: "100%",
                      }}>
                      <div className=" fw-bold">{notification.subject}</div>
                      <div style={{ textAlign: "justify" }}>
                        {notification.description}
                      </div>
                    </div>
                  </div>
                );
              })}
              {notificationList.length === 0 && (
                <div className=" text-center m-5">
                  you have not any notification
                </div>
              )}
            </div>
          </Box>
        )}
        <LogOutModal show={logOutShow} setShow={setLogOutShow} />
      </Box>
    </Box>
  );
};

export default Topbar;

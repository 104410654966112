import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { FaTrash, FaPlus } from "react-icons/fa";
import "./Member.css";
import InvitationSendModal from "./InvitationSendModal";
import { getAllUsersInCompany } from "../../api/axios";
import { jwtDecode } from "jwt-decode";
import DeleteModal from "../DeleteModal";

const Member = () => {
  const [show, setShow] = useState(false);
  const [memberData, setmemberData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteMember, setDeleteMember] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const fetchmemberData = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersInCompany();
      setmemberData(response.data.users);
    } catch (error) {
      console.error("Error fetching member data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchmemberData();
  }, []);

  const handleShow = () => setShow(true);

  const handleDelete = (member) => {
    setDeleteMember(member);
    setDeleteShow(true);
  };

  return (
    <div className="member-container">
      <div
        className="member-header"
        style={{
          display: "flex",
          alignmembers: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="member-title">
          <h2 style={{ fontWeight: "600", color: "#342352" }}>Members</h2>
        </div>
        {decodedToken?.jobTitle === "System_Admin" && (
          <div className="member-modal">
            <button className="member-modal-button" onClick={handleShow}>
              <FaPlus /> Send Invitation
            </button>
          </div>
        )}
      </div>

      <div className="member-table">
        <MDBTable striped>
          <MDBTableHead>
            <tr>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">JobTitle</th>
              {decodedToken?.jobTitle === "System_Admin" && (
                <th scope="col">Action</th>
              )}
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loading ? (
              <span style={{ fontSize: "16px" }}>Loading...</span>
            ) : memberData.length > 0 ? (
              memberData.map((member, index) => (
                <tr key={index}>
                  <td>{member.firstName}</td>
                  <td>{member.lastName} </td>
                  <td>{member.email}</td>
                  <td>{member.jobTitle}</td>
                  {decodedToken?.jobTitle === "System_Admin" && (
                    <td className="action-column">
                      {member.jobTitle === "System_Admin" ? (
                        " "
                      ) : (
                        <FaTrash
                          className="delete-icon"
                          onClick={() => handleDelete(member)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <span style={{ fontSize: "16px" }}>No Members!</span>
            )}
          </MDBTableBody>
        </MDBTable>
      </div>
      <InvitationSendModal show={show} setShow={setShow} />
      <DeleteModal 
        show={deleteShow}
        setShow={setDeleteShow}
        title={"company-user"}
        deleteMember={deleteMember}
        fetchmemberData={fetchmemberData}
      />
    </div>
  );
};

export default Member;

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const MultipleValueDoughnut = ({ dataPoints, colors }) => {
  const data = {
    labels: dataPoints?.map(point => point.name),
    datasets: [
      {
        data: dataPoints?.map(point => point.count),
        backgroundColor: colors,
        hoverBackgroundColor: colors.map(color => `${color}B0`),
        cutout: "70%",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false, 
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        roundedCornersFor: {
          top: true,
          bottom: true,
        },
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: "100%",
        height: "auto",
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default MultipleValueDoughnut;

import React, { useState } from "react";
import { Box, Typography, Button, TextField, InputLabel } from "@mui/material";
import image from "../../../assets/images/loginCompany.png";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { CompanyLoginAPI } from "../../../api/axios";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#F1EAFF",
    padding: "2rem 15rem",

    [theme.breakpoints.down("lg")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  centerBox: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "red",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  boxLeft: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    backgroundColor: "white",
    padding: "3rem 4rem",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "2rem",
  },
  boxRight: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5rem",
    alignItems: "center",
    position: "relative",
    width: "50%",
    background:
      "linear-gradient(180deg, rgba(35, 21, 65, 1) 0%, rgba(226, 178, 255, 1) 180%)",
  },
}));

const LoginCompany = () => {
  const classes = useStyles();
  const [companyKey, setCompanyKey] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setError("");
    setCompanyKey(e.target.value);
    console.log(companyKey);
  };

  const validate = () => {
    let isValid = true;
    if (!companyKey) {
      setError("Company key is required");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const requestBody = {
          companyKey: companyKey,
        };
        const response = await CompanyLoginAPI(requestBody);
        //console.log(response);
        if (response.status === 200) {
          localStorage.setItem("hasCompany", false);
          CustomToast.success(response.data.message);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          setLoading(false);
        } else {
          CustomToast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          CustomToast.error(error.response.data.message);
          setLoading(false);
        } else {
          CustomToast.error("Something went wrong");
          setLoading(false);
        }
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.centerBox}>
        <Box className={classes.boxLeft}>
          <Typography
            sx={{ fontWeight: "600", fontFamily: "'Poppins', sans-serif" }}
            variant="h1"
          >
            Join With Your Company
          </Typography>
          <Box className={classes.formBox}>
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-name"
            >
              Company Key
            </InputLabel>
            <TextField
              sx={{ mb: 1 }}
              id="companyKey"
              label="Enter company key"
              variant="outlined"
              value={companyKey}
              onChange={handleChange}
              error={error}
            />
            {error && (
              <Typography sx={{ color: "red", fontSize: 12 }}>
                {error}
              </Typography>
            )}
            <Button
              sx={{
                marginTop: "2rem",
                backgroundColor: "#342352",
                color: "white",
                width: "100%",
                height: "40px",
                alignSelf: "center",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "Login"}
            </Button>
          </Box>
        </Box>
        <Box className={classes.boxRight}>
          <img
            src={image}
            width={600}
            height={600}
            style={{ position: "absolute", bottom: "-130px", right: "-90px" }}
            alt="login"
          />

          <Typography
            sx={{
              color: "white",
              fontSize: 45,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
              textAlign: "center",
            }}
            variant="h1"
          >
            Join the Company
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 16,
              m: 1,
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="p"
          >
            Enter your Credentials
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 20,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h2"
          >
            OR
          </Typography>
          <RouterLink to="/" style={{ textDecoration: "none", width: "50%" }}>
            <Button
              sx={{
                margin: "1rem 0",
                backgroundColor: "transparent",
                color: "white",
                width: "100%",
                height: "45px",
                alignSelf: "center",
                border: "1px solid rgba(255,255,255,0.5)",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
              onClick={() => {
                localStorage.clear();
              }}
            >
              Login
            </Button>
          </RouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginCompany;

import React from "react";
import StatusGrid from "../../../components/Board/StatusGrid";


const Board = () => {


  return (
    <div>
      <StatusGrid />
    </div>
  );
};

export default Board;

import "./Progress.css";

const DetailCart = ({ image, color, value, text }) => {
  return (
    <div className="detailCart-container">
      <div className="detailCart-left">
        <img
          src={image}
          className="detailCart-img"
          style={{ backgroundColor: color }}
          alt=""
        />
        <div className="detailCart-txt">
          <span style={{ fontWeight: "bold" }}>{value}</span>
          <span>{text}</span>
        </div>
      </div>
      <div
        className="detailCart-right"
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
};

export default DetailCart;

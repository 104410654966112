import { Modal, Button, Box, Typography } from "@mui/material";
import React, {  useState } from "react";
import CustomToast from "./CustomToast/CustomToast";
import {
  deleteProject,
  deleteRequirement,
  removeDeveloperFromCompany,
  removeDeveloperFromProject,
} from "../api/axios";
import { useProject } from "../context/ProjectContext";
const DeleteModal = ({
  show,
  setShow,
  title,
  deleteID,
  projectID,
  GetAllRequirements,
  GetAllDevelopersInProject,
  deleteMember,
  fetchmemberData,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(!show);
  };
  const { selectProject } = useProject();
  const handleDelete = async () => {
    setLoading(true);
    if (title === "requirement") {
      try {
        const res = await deleteRequirement(deleteID);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          GetAllRequirements(projectID);
          handleClose();
        } else {
          CustomToast.error("Requirement delete failed!");
        }
      } catch (error) {
        console.log("Error delete requirement", error);
      } finally {
        setLoading(false);
      }
    }
    if (title === "project") {
      try {
        const res = await deleteProject(deleteID);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          handleClose();
          selectProject("");
          window.location.href = "/Company";
        } else {
          CustomToast.error("Project delete failed!");
        }
      } catch (error) {
        console.log("Error delete project", error);
      } finally {
        setLoading(false);
      }
    }
    if (title === "project-developer") {
      try {
        const reqBody = {
          userId: deleteID,
          projectId: projectID,
        };
        
        const res = await removeDeveloperFromProject(reqBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          handleClose();
          GetAllDevelopersInProject(projectID);
          window.location.reload();
        } else {
          CustomToast.error("Developer remove failed!");
        }
      } catch (error) {
        console.log("Error remove developer", error);
        CustomToast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    }
    if (title === "company-user") {
      try {
        const reqBody = {
          userId: deleteMember.id,
          companyId: deleteMember.companyId,
        };
        
        const res = await removeDeveloperFromCompany(reqBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          handleClose();
          fetchmemberData();
        } else {
          CustomToast.error("User remove failed!");
        }
      } catch (error) {
        console.log("Error remove user", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "24px",
            color: "#6A6A6A",
          }}>
          Confirm Deletion
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "16px",
            mt: "10px",
            color: "#AFAAAA",
            fontWeight: "500",
            textAlign: "center",
          }}>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            gap: "10px",
            mt: "30px",
            width: "100%",
          }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: "#6c757d",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#5a6268",
              },
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{
              backgroundColor: "#dc3545",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                backgroundColor: "#c82333",
              },
            }}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;

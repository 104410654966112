import "./Board.css";
import { Box, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import IssueCart from "./IssueCart";
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState, useRef } from "react";
import IssueCreateModal from "./IssueCreateModal";
import ThreeDoteMenu from "../ThreeDoteMenu/ThreeDoteMenu";
import ColumnEditModal from "./ColumnEditModal";
import { jwtDecode } from "jwt-decode";
import { useProject } from "../../context/ProjectContext";
import { getProjectUserRole } from "../../api/axios";

const StatusColumn = ({ item, index }) => {
  const [show, setShow] = useState(false);
  const [showEditColumnModal, setShowEditColumnModal] = useState(false);
  const { selectedProjectId,memberID } = useProject();
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);
  const [projectRole, setProjectRole] = useState("");
  const [isThreeDoteModelOpen, setIsThreeDoteModelOpen] = useState(false);
  const tdmRef = useRef(null);

  const GetProjectUserRole = async (id) => {
    try {
      const data = await getProjectUserRole(id);
      setProjectRole(data.data.result.result);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetProjectUserRole(selectedProjectId);
    }
  }, [selectedProjectId]);

  const handleClickCreateIssue = () => {
    setShow(true);
  };

  const toggleThreeDoteOpen = () => {
    setIsThreeDoteModelOpen(!isThreeDoteModelOpen);
  };

  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
        >
          <Droppable droppableId={item.id.toString()} type="task">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <div className="status-column">
                  <div className="status-column-top">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "600",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "600",
                          }}
                        >
                          {item.tasks.length}
                        </Typography>
                      </Box>
                      {(decodedToken?.jobTitle === "System_Admin" ||
                        projectRole?.role === "Project_Manager" ||
                        projectRole?.role === "Business_Analyst") && (
                        <Box
                          onClick={toggleThreeDoteOpen}
                          sx={{
                            padding: "2px",
                            borderRadius: "3px",
                            "&:hover": {
                              cursor: "pointer",
                              backgroundColor: "#E0E0DE",
                            },
                          }}
                          ref={tdmRef}
                        >
                          <MoreHorizIcon />
                        </Box>
                      )}
                    </Box>
                    <span
                      style={{
                        width: "100%",
                        borderTop: "2px solid #E0E0DE",
                      }}
                    ></span>
                  </div>

                  <div className="status-column-bottom-container">
                    <div className="status-column-bottom">
                      {item.tasks?.filter(item =>memberID != null ? item.assignedUser.id === memberID : item).map((issue, index) => (
                        <IssueCart key={issue.id} issue={issue} index={index} />
                      ))}
                      {droppableProvided.placeholder}
                      {(decodedToken?.jobTitle === "System_Admin" ||
                        projectRole?.role === "Project_Manager" ||
                        projectRole?.role === "Business_Analyst") && (
                        <div
                          className="issue-add-icon"
                          onClick={handleClickCreateIssue}
                        >
                          <AddIcon />
                        </div>
                      )}
                    </div>
                  </div>
                  <ColumnEditModal
                    show={showEditColumnModal}
                    setShow={setShowEditColumnModal}
                    columnData={item}
                  />
                  <IssueCreateModal
                    item={item}
                    show={show}
                    setShow={setShow}
                    title={"create"}
                  />
                  <ThreeDoteMenu
                    isThreeDoteModelOpen={isThreeDoteModelOpen}
                    setIsThreeDoteModelOpen={setIsThreeDoteModelOpen}
                    toggleThreeDoteOpen={toggleThreeDoteOpen}
                    setShowEditColumnModal={setShowEditColumnModal}
                    item={item}
                    tdmRef={tdmRef}
                  />
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default StatusColumn;

import React from "react";
import { Typography, Box } from "@mui/material";
import { Download } from "@mui/icons-material";
import pdfLogo from "../../assets/images/pdf.png"
import zipLogo from "../../assets/images/Zip.png"
import fileLogo from "../../assets/images/file.png"


const DocumentView = ({ attachment }) => {

  const imageTypes = ["png", "jpg", "jpeg", "gif", "bmp", "webp", "PNG"];
  const videoTypes = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm", "mpeg"];

  const attachmentView = (attachment) => {
    const fileType = getFileTypeFromName(attachment?.name);
    if (imageTypes.includes(fileType)) {
      return (
        <div>
          <img
            style={{ width: "50px", height: "50px", cursor: "all-scroll" }}
            src={attachment.link}
            alt=""
          />
        </div>
      );
    }
    if (videoTypes.includes(fileType)) {
      return (
        <div>
          <video
            style={{ width: "50px", height: "50px", cursor: "all-scroll" }}
            src={attachment.link}
            muted
          />
        </div>
      );
    }
    if (fileType === "pdf") {
      return (
        <div>
          <img style={{ width: "50px", height: "50px" }} src={pdfLogo} alt="" />
        </div>
      );
    }
    if (fileType === "zip") {
      return (
        <div>
          <img style={{ width: "50px", height: "50px" }} src={zipLogo} alt="" />
        </div>
      );
    }
    return (
      <div>
        <img
          style={{ width: "50px", height: "50px", cursor: "pointer" }}
          src={fileLogo}
          alt=""
        />
      </div>
    );
  };

  const getFileTypeFromName = (name) => {
    if (typeof name === "string" && name.includes(".")) {
      const parts = name.split(".");
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  };

  const handleDownload = (url, filename) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div>
      <Typography
        sx={{
          color: "#342352",
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Attachments
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {attachmentView(attachment)}
        <Typography
          sx={{
            color: "#342352",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          {attachment.name}
        </Typography>
        <div
          onClick={() => handleDownload(attachment.link, attachment.name)}
          type="button"
          
        >
          <Download />
        </div>
      </Box>
    </div>
  );
};

export default DocumentView;

import { Modal } from "react-bootstrap";
import React, {useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./Board.css";
import TaskAttachmentView from "./TaskAttachmentView";
import { getTaskById } from "../../api/axios";
import IssueEditModal from "./IssueEditModal";
import DeleteModal from "../DeleteModalForBoard";

const ViewIssueModal = ({ show, setShow, taskId }) => {
  const [selectedIssue, setSelectedIssue] = useState({});
  const [showEditTaskForm, setShowEditTaskForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getTaskData = async () => {
    try {
      const taskData = await getTaskById(taskId);
      if (taskData.data.result.success) {
        setSelectedIssue(taskData.data.result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      getTaskData();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  const getBackgroundColor = (flag) => {
    if (flag === "hard") {
      return "red";
    }
    if (flag === "medium") {
      return "#FFD700";
    }
    return "#019E4B";
  };

  const formatDateToReadable = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const deleteCardHandler = async () => {
    try {
      setShowDeleteModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body className=" p-4 px-4">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                Task Name:
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}
              >
                {selectedIssue.name}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                Flag:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "white",
                  backgroundColor: getBackgroundColor(selectedIssue.flag),
                  padding: "0px 10px",
                  borderRadius: "3px",
                }}
              >
                {selectedIssue.flag}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                Assign to:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}
              >
                {`${selectedIssue.assignedUser?.firstName} ${selectedIssue.assignedUser?.lastName} `}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                Report to:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}
              >
                {`${selectedIssue.reportUser?.firstName} ${selectedIssue.reportUser?.lastName} `}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                Start Date:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}
              >
                {formatDateToReadable(selectedIssue.startDate)}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                }}
              >
                End Date:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#AFAAAA",
                }}
              >
                {formatDateToReadable(selectedIssue.endDate)}
              </Typography>
            </Box>
            {selectedIssue.linkingTask !== null && (
              <>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: "600",
                    }}
                  >
                    Linked task:
                  </Typography>
                </Box>
                <Box
                  style={{
                    alignItems: "center",
                    gap: "10px",
                    backgroundColor: "#efefef",
                    border: "1px solid #aaa",
                    padding: "6px",
                    borderRadius: "3px",
                  }}
                >
                  <div className=" d-flex gap-2">
                    <div style={{ fontWeight: "bold" }}> Name: </div>
                    <div>{selectedIssue.linkingTask?.name}</div>
                  </div>
                  {selectedIssue.linkingTask?.description && (
                    <>
                      <div style={{ fontWeight: "bold" }}> Description:</div>
                      <div style={{ textAlign: "justify" }}>
                        {selectedIssue.linkingTask?.description}
                      </div>
                    </>
                  )}
                </Box>
              </>
            )}
            {selectedIssue.description !== "" && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Description:
                </Typography>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "'Poppins', sans-serif",
                    color: "#AFAAAA",
                  }}
                >
                  {selectedIssue.description}
                </Typography>
              </Box>
            )}
            {selectedIssue.Files && selectedIssue.Files.length > 0 && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Attachments:
                </Typography>
                {selectedIssue.Files &&
                  selectedIssue.Files.map((file, index) => (
                    <div key={index}>
                      <TaskAttachmentView file={file} />
                    </div>
                  ))}
              </Box>
            )}
            <Box display="flex" justifyContent="center" gap="10px" mt="20px">
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  setShowEditTaskForm(true);
                  setShow(false);
                }}
                sx={{
                  backgroundColor: "#231541",
                  color: "white",
                  width: "120px",
                  height: "40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "purple",
                  },
                }}
              >
                Edit Task
              </Button>{" "}
              <Button
                type="submit"
                variant="contained"
                onClick={deleteCardHandler}
                sx={{
                  backgroundColor: "#E34545",
                  color: "white",
                  width: "140px",
                  height: "40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "purple",
                  },
                }}
              >
                Delete Task
              </Button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>

      <DeleteModal
        title="task"
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        deleteID={selectedIssue}
      />
      <IssueEditModal
        issue={selectedIssue}
        show={showEditTaskForm}
        setShow={setShowEditTaskForm}
      />
    </div>
  );
};

export default ViewIssueModal;

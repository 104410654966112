import React from "react";
import EditProfile from "../../../components/Profile/EditProfile";


const Profile = () => {


  return (
    <div>
      <EditProfile />
    </div>
  );
};

export default Profile;

import "./Progress.css";
import { Box, Typography } from "@mui/material";

const ContributionCart = ({ item }) => {
  return (
    <div className="contributionCart-container">
      <div className="contribution-member-img">
        {item.imageURL ? (
          <img
            src={item.imageURL}
            alt=""
            style={{
              width: "60px",
              height: "auto",
            }}
          />
        ) : (
          <div
            style={{
              backgroundColor: "#abcd",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >{`${item.firstName?.charAt(0)}${item.lastName?.charAt(0)}`}</div>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              color: "#AFAAAA",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            {item.firstName} {item.lastName}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Poppins', sans-serif",
              color: "#B982FF",
              fontWeight: "600",
            }}
          >
            Developer
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "10px",
            backgroundColor: "#E0E0DE",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              width: `${item.contribution}%`,
              height: "10px",
              backgroundColor: "#FB9E42",
              borderRadius: "20px",
            }}
          ></Box>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            marginTop: "5px",
            textAlign: "right",
            color: "#858585",
            fontWeight: "600",
          }}
        >
          {item.contribution}%
        </Typography>
      </div>
    </div>
  );
};

export default ContributionCart;

import React, { useState } from "react";
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const Layout = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Sidebar isSidebar={isSidebar} />
      <main
        className="content"
        style={{
          backgroundColor: colors.primary[400],
          overflowX: "auto",
        }}
      >
        <Topbar setIsSidebar={setIsSidebar} />
        {children}
      </main>
    </>
  );
};

export default Layout;

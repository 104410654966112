import React, { useState, useEffect } from "react";
import "./Profile.css";
import { jwtDecode } from "jwt-decode";
import { getUserDetails } from "../../api/axios";
import EmailIcon from "@mui/icons-material/Email";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PhoneIcon from "@mui/icons-material/Phone";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DetailEditModal from "./DetailsEditModal";
import EditImageModal from "./EditImageModal";

const EditProfile = () => {
  const [user, setUser] = useState({});
  const [imageEditShow, setImageEditShow] = useState(false);
  const [detailsEditShow, setDetailEditShow] = useState(false);
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const GetUserDetails = async (id) => {
    try {
      const res = await getUserDetails(id);
      setUser(res.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    GetUserDetails(decodedToken.userId);
  }, [decodedToken.userId]);

  const handleEditImage = () => setImageEditShow(true);
  const handleEditDetails = () => setDetailEditShow(true);

  return (
    <div className="profile-container">
      <div className="profile">
        <div className="profile-left-div">
          <div className="profile-left">
            {user.imageURL ? (
              <img
                alt="profile-user"
                src={user.imageURL}
                className="profile-left-image"
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#abcd",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px"
                }}
              >{`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}</div>
            )}
          </div>
          <div className="camera-icon" onClick={handleEditImage}>
            <CameraAltIcon />
          </div>
          <div className="name">
            <span>{user.firstName}</span>
            <span>{user.lastName}</span>
          </div>
        </div>

        <div className="profile-right">
          <div className="profile-detail">
            <EmailIcon />
            <span>{user.email}</span>
          </div>
          <div className="profile-detail">
            <BusinessCenterIcon />
            <span>{user.jobTitle}</span>
          </div>
          <div className="profile-detail">
            <PhoneIcon />
            <span>{user.contactNumber}</span>
          </div>
          <button className="profile-edit-button" onClick={handleEditDetails}>
            Edit Profile Details
          </button>
        </div>

        <DetailEditModal
          show={detailsEditShow}
          setShow={setDetailEditShow}
          item={user}
          userId={decodedToken.userId}
          GetUserDetails={GetUserDetails}
        />

        <EditImageModal
          show={imageEditShow}
          setShow={setImageEditShow}
          image={user.imageURL}
          userId={decodedToken.userId}
          GetUserDetails={GetUserDetails}
        />
      </div>
    </div>
  );
};

export default EditProfile;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProjectProvider } from "./context/ProjectContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <ProjectProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProjectProvider>
);

import { Box, Typography, FormHelperText, Button, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomToast from "../CustomToast/CustomToast";
import { updateUserImage } from "../../api/axios";
import "./Profile.css";
import CropEasy from "./Crop/CropEasy";

const EditImageModal = ({ show, setShow, image, userId, GetUserDetails }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [file, setFile] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  useEffect(() => {
    setImageURL(image);
    setFile(image);
  }, [image, show, setShow]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleImageClick = () => {
    document.getElementById("image-upload").click();
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const fileURL = URL.createObjectURL(selectedFile);

      setFile(selectedFile);
      setImageURL(fileURL);
      setOpenCrop(true);
    } else {
      setFile(imageURL);
      setImageURL(file);
    }

    setValidationErrors({
      ...validationErrors,
      file: "",
    });
  };

  const setCroppedImage = (croppedData) => {
    setImageURL(croppedData.url);
    setFile(croppedData.file);
  };

  const validateForm = () => {
    const errors = {};
    if (!file) {
      errors.file = "Image is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("imageURL", file);

        const res = await updateUserImage(userId, formData);
        if (res.data.result.status) {
          CustomToast.success(res.data.result.message);
          setShow(!show);
          GetUserDetails(userId);
          handleClose();
        } else {
          CustomToast.error("Image update failed!");
        }
      } catch (error) {
        console.log("Error update image", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return !openCrop ? (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: 24,
            py: 4,
            px: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
            }}
          >
            Change Profile Image
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              onClick={handleImageClick}
            >
              <div className="profile-left" style={{ cursor: "pointer" }}>
                {imageURL ? (
                  <img
                    alt="profile-user"
                    src={imageURL}
                    className="profile-left-image"
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#abcd",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >{"Click here!"}</div>
                )}
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </Box>
            {validationErrors.file && (
              <FormHelperText style={{ color: "#f44336" }}>
                {validationErrors.file}
              </FormHelperText>
            )}
            {/* <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: 15,
                  fontFamily: "'Poppins', sans-serif",
                }}
                htmlFor="outlined-adornment-name"
              >
                Upload Image
              </InputLabel>
              <TextField
                fullWidth
                label=" "
                name="file"
                inputProps={{
                  accept: "image/*",
                }}
                onChange={handleFileChange}
                variant="filled"
                type="file"
                error={!!validationErrors.file}
              />
              {validationErrors.file && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {validationErrors.file}
                </FormHelperText>
              )}
            </Box> */}
            <Box display="flex" justifyContent="center" mt="20px" gap="10px">
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#c82333",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#231541",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "purple",
                  },
                }}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  ) : (
    <CropEasy
      photoURL={imageURL}
      setOpenCrop={setOpenCrop}
      setCroppedImage={setCroppedImage}
    />
  );
};

export default EditImageModal;

import React from "react";
import ProjectSetting from "../../../components/BasicSetting/ProjectSetting";
import MemberSetting from "../../../components/BasicSetting/MemberSetting";

const Setting = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px 30px",
      }}
    >
      <ProjectSetting />
      <MemberSetting />
    </div>
  );
};

export default Setting;

import { Outlet, Navigate} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectRoutes = () => {
    const token = localStorage.getItem("tokenCompany") || localStorage.getItem("token");
    let hasCompany = false;
    if (token) {
        try {
        const decodedToken = jwtDecode(token);
        hasCompany = decodedToken.hasCompany;
        } catch (error) {
        console.error("Invalid token", error);
        }
    }
    const auth = (localStorage.getItem("token") || localStorage.getItem("tokenCompany")) && hasCompany === true  ? true : false;

    return(
        auth ? <Outlet /> : <Navigate to="/" />
    )
}

export default ProtectRoutes;
import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { FaTrash, FaPlus } from "react-icons/fa";
import "./Project.css";
import {
  formatDate,
  getAllProjects,
  getAllProjectsForDeveloper,
} from "../../api/axios";
import { jwtDecode } from "jwt-decode";
import { useProject } from "../../context/ProjectContext";
import ProjectCreateModal from "./ProjectCreateModal";
import DeleteModal from "../DeleteModal";

const Project = () => {
  const { selectProject } = useProject();
  const [show, setShow] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [deleteID, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const fetchProjectData = async () => {
    if (decodedToken?.jobTitle === "System_Admin") {
      setLoading(true);
      try {
        const response = await getAllProjects(decodedToken.companyId);
        setProjectData(response.data.projects);
      } catch (error) {
        console.error("Error fetching project data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const res = await getAllProjectsForDeveloper(decodedToken.userId);
        setProjectData(res.data.projects);
      } catch (error) {
        console.error("Error fetching project data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  const handleShow = () => setShow(true);

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleProjectClick = (projectId) => {
    selectProject(projectId);
  };

  const LimitDescription = (description) => {
    return description.length > 30
      ? description.substring(0, 30) + "..."
      : description;
  };

  return (
    <div className="project-container">
      <div
        className="company-settings"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="company-header">
          <h2 style={{ fontWeight: "600", color: "#342352" }}>Projects</h2>
        </div>
        {decodedToken?.jobTitle === "System_Admin" && (
          <div className="project-modal">
            <button className="project-modal-button" onClick={handleShow}>
              <FaPlus /> Add New Project
            </button>
          </div>
        )}
      </div>

      <div className="project-table">
        <MDBTable striped>
          <MDBTableHead>
            <tr>
              <th scope="col">Project</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Description</th>
              {decodedToken?.jobTitle === "System_Admin" && (
                <th scope="col">Action</th>
              )}
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loading ? (
              <span style={{ fontSize: "16px" }}>Loading...</span>
            ) : projectData.length > 0 ? (
              projectData.map((project) => (
                <tr key={project.projectId}>
                  <td
                    onClick={() => handleProjectClick(project.projectId)}
                    style={{ cursor: "pointer" }}
                    title={project.projectName}
                  >
                    {LimitDescription(project.projectName)}
                  </td>
                  <td
                    onClick={() => handleProjectClick(project.projectId)}
                    style={{ cursor: "pointer" }}
                  >
                    {formatDate(project.startDate)}
                  </td>
                  <td
                    onClick={() => handleProjectClick(project.projectId)}
                    style={{ cursor: "pointer" }}
                  >
                    {project.endDate}
                  </td>
                  <td
                    onClick={() => handleProjectClick(project.projectId)}
                    style={{ cursor: "pointer" }}
                    title={project.description}
                  >
                    {LimitDescription(project.description)}
                  </td>
                  {decodedToken?.jobTitle === "System_Admin" && (
                    <td className="action-column">
                      <FaTrash
                        className="delete-icon"
                        onClick={() => handleDelete(project.projectId)}
                      />
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <span style={{ fontSize: "16px" }}>No Projects!</span>
            )}
          </MDBTableBody>
        </MDBTable>
      </div>

      <ProjectCreateModal
        show={show}
        setShow={setShow}
        title={"create"}
        companyId={decodedToken.companyId}
        fetchProjectData={fetchProjectData}
      />

      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        title={"project"}
        deleteID={deleteID}
      />
    </div>
  );
};

export default Project;

import React, { useState } from "react";
import "./Requirement.css";
import { Box, Typography } from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import RequirementCreateModal from "./RequirementCreateModal";
import reqIcon from "../../assets/images/requirement.png";
import DeleteModal from "../DeleteModal";
import { jwtDecode } from "jwt-decode";
import DocumentView from "./DocumentView";

const RequirementCard = ({
  item,
  projectID,
  GetAllRequirements,
  projectRole,
}) => {
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteID, setDeleteId] = useState();
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const handleClickEditRequirement = () => {
    setShow(true);
  };

  const handleClickDeleteRequirement = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  return (
    <div className="requirementCard-container">
      <Typography
        sx={{
          color: "#342352",
          fontSize: "18px",
          fontWeight: "600",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        <img
          src={reqIcon}
          alt=""
          style={{
            width: "25px",
            height: "25px",
            marginLeft: "-4px",
            marginTop: "-10px",
            marginRight: "5px",
          }}
        />
        {item.title}
      </Typography>
      {item.description && <span>{item.description}</span>}
      {item.attachment && <DocumentView attachment={item.attachment} />}
      {(decodedToken?.jobTitle === "System_Admin" ||
        projectRole?.role === "Project_Manager" ||
        projectRole?.role === "Business_Analyst") && (
        <Box
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <div
            className="r-delete-icon"
            onClick={() => handleClickDeleteRequirement(item.requirementId)}
          >
            <FaTrash />
          </div>
          <div
            className="r-edit-icon"
            onClick={() => handleClickEditRequirement()}
          >
            <FaEdit />
          </div>
        </Box>
      )}
      <RequirementCreateModal
        show={show}
        setShow={setShow}
        title={"update"}
        item={item}
        projectID={projectID}
        GetAllRequirements={GetAllRequirements}
      />
      <DeleteModal
        show={deleteShow}
        setShow={setDeleteShow}
        title={"requirement"}
        deleteID={deleteID}
        projectID={projectID}
        GetAllRequirements={GetAllRequirements}
      />
    </div>
  );
};

export default RequirementCard;

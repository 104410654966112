import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const ProgressDoughnut = ({ progress, color }) => {
  const data = {
    datasets: [
      {
        data: [progress, 100 - progress],
        backgroundColor: [color, "#F6F6F4"],
        hoverBackgroundColor: ["#947cb4", "#e0e0e0"],
        cutout: "70%",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        roundedCornersFor: {
          top: true,
          bottom: true,
        },
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: "100%",
        height: "auto",
      }}
    >
      <Doughnut data={data} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "30px", color: "#6A6A6A", fontWeight: "500" }}>
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default ProgressDoughnut;

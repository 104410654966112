import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArticleIcon from '@mui/icons-material/Article';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { useProject } from "../../context/ProjectContext";
import CustomToast from "../../components/CustomToast/CustomToast";
const darkPurple = "#2c2040"; // Dark purple color
const lightPurple = "#947cb4"; // Light purple color

const Item = ({ title, to, icon, selected, onClick, isDisabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "white",
        position: "relative",
        marginBottom: "30px",
        cursor: isDisabled ? "not-allowed" : "pointer"
      }}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          onClick();
        } else {
          onClick();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      icon={icon}
    >
      <Typography sx={{ fontSize: "1.1rem" }}>{title}</Typography>
      {!isDisabled && <Link to={to} />}

      {isHovered && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            borderRadius: "5px",
            height: "100%",
            backgroundColor: "rgba(178, 190, 181, 0.4);",
            zIndex: -1,
          }}
        />
      )}
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const location = window.location.pathname;
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(location.replace(/^\//, ""));
  const gradientBackground = `linear-gradient(to bottom, ${darkPurple}, ${lightPurple})`;
  
  const { selectedProjectId } = useProject();
  const navigate = useNavigate();

  const handleMenuItemClick = (title, to) => {
    if (!selectedProjectId && to !== "/Company") {
      CustomToast.warning("Select a project from the projects table to continue.");
      return;
    }
    setSelected(title);
    navigate(to);
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${gradientBackground} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menu-item.active": {
          backgroundColor: "rgba(178, 190, 181, 0.4) !important",
          borderRadius: "5px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[900],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon
                    style={{
                      fontSize: "2rem",
                      marginLeft: "30%",
                      color: colors.grey[900],
                    }}
                  />
                </IconButton>

                <Box
                  justifyContent="center"
                  alignItems="center"
                  marginRight={"25%"}
                >
                  <img
                    alt="profile-user"
                    width="70px"
                    height="70px"
                    src={`../../assets/download.png`}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            )}
          </MenuItem>

          <Box
            style={{
              marginTop: "20%",
              paddingLeft: isCollapsed ? undefined : "10%",
              paddingRight: isCollapsed ? undefined : "10%",
            }}
          >
            <Item
              title="Company"
              to="/Company"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              onClick={() => handleMenuItemClick("Company", "/Company")}
              isDisabled={false}
            />

            <Item
              title="Board"
              to="/Board"
              icon={<ViewWeekIcon />}
              selected={selected}
              onClick={() => handleMenuItemClick("Board", "/Board")}
              isDisabled={!selectedProjectId}
            />
            <Item
              title="Progress"
              to="/Progress"
              icon={<BarChartIcon />}
              selected={selected}
              onClick={() => handleMenuItemClick("Progress", "/Progress")}
              isDisabled={!selectedProjectId}
            />

            <Item
              title="Requirement"
              to="/Requirement"
              icon={<ArticleIcon />}
              selected={selected}
              onClick={() => handleMenuItemClick("Requirement", "/Requirement")}
              isDisabled={!selectedProjectId}
            />
            <Item
              title="Settings"
              to="/Settings"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              onClick={() => handleMenuItemClick("Settings", "/Settings")}
              isDisabled={!selectedProjectId}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedCompanySignup = ({ children }) => {
    const tokenCompany = localStorage.getItem("tokenCompany") || localStorage.getItem("token");
    let jobTitle = "";
    if (tokenCompany) {
        try {
        const decodedToken = jwtDecode(tokenCompany);
        jobTitle = decodedToken.jobTitle;
        } catch (error) {
        console.error("Invalid token", error);
        }
    }
    const auth = localStorage.getItem("tokenCompany") && localStorage.getItem("status") && jobTitle === 'System_Admin' && localStorage.getItem("hasCompany") === "false" ? true : false;

    return auth ? children : <Navigate to={'/'}/>;
};

export default ProtectedCompanySignup;
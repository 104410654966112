import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";
import Cropper from "react-easy-crop";
import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import getCroppedImg from "./utils/cropImage";

const CropEasy = ({ photoURL, setOpenCrop, setCroppedImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    try {
      const croppedData = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedData);
      setOpenCrop(false);
    } catch (e) {
      console.error(e);
    }
  }, [photoURL, croppedAreaPixels, rotation, setCroppedImage, setOpenCrop]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "auto",
          backgroundColor: "white",
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
        }}
      >
        <DialogContent
          dividers
          sx={{
            background: "#333",
            position: "relative",
            height: 300,
            width: "auto",
            minWidth: { sm: 400 },
          }}
        >
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
          <Box sx={{ width: "100%", mb: 1 }}>
            <Box>
              <Typography sx={{ fontFamily: "'Poppins', sans-serif" }}>
                Zoom: {zoomPercent(zoom)}
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            <Box>
              <Typography sx={{ fontFamily: "'Poppins', sans-serif" }}>
                Rotation: {rotation}
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={360}
                value={rotation}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Button
              variant="outline"
              startIcon={<Cancel />}
              onClick={() => setOpenCrop(false)}
              sx={{fontFamily: "'Poppins', sans-serif", border: "1px solid #333"}}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={<CropIcon />}
              onClick={cropImage}
              sx={{fontFamily: "'Poppins', sans-serif"}}
            >
              Crop
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

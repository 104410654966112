import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { sendInvitation, getCompanyDetails } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";

const InvitationSendModal = ({ show, setShow }) => {
  const initialState = {
    email: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});

  const GetCompanyDetails = async () => {
    try {
      const res = await getCompanyDetails();
      setCompany(res.data.userCompany);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    GetCompanyDetails();
  }, []);

  const handleClose = () => {
    setShow(false);
    setFormData(initialState);
    setValidationErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      try {
        const requestBody = {
          company: company.companyname,
          companyKey: company.companyKey,
          mail: formData.email,
        };

        const res = await sendInvitation(requestBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          handleClose();
        } else {
          CustomToast.error("Invitation send failed!");
        }
      } catch (error) {
        console.log("Error invitation send", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}
            >
              Send Invitation
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  type="text"
                  label="Enter a email"
                  variant="filled"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!!validationErrors.email}
                />
                {validationErrors.email && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.email}
                  </FormHelperText>
                )}
              </Box>

              <Box display="flex" justifyContent="right" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    padding: "8px 40px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}
                >
                  {isLoading ? "Sending..." : "Send"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InvitationSendModal;

import axios from "axios";

const BASE_URL = "https://pmapi.pm.hasthiya.org";
// http://localhost:8000/
// "https://pmapi.pm.hasthiya.org"

const token =
  localStorage.getItem("token") || localStorage.getItem("tokenCompany");

export const signupURL = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
});

export const baseURL = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export const multipartAPI = axios.create({
  baseURL: BASE_URL,
  timeout: 400000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
});

export const CompanyURL = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const RegisterUser = async (requestBody) => {
  try {
    const res = await signupURL.post(`/users/register`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const CompanyRegister = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/company/createcompany`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const LoginAPI = async (requestBody) => {
  try {
    const res = await signupURL.post(`/users/login`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const CompanyLoginAPI = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/company/checkcompany`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};
export const SendOTP = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/users/sendOTP`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};
export const confirmEmail = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/users/validateOtp`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};
export const changePassword = async (requestBody) => {
  try {
    const res = await CompanyURL.put(
      `/users/ChangePasswordWithEmail`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const CreateProject = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/projects/create`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const GetProjectByID = async (id) => {
  try {
    const res = await CompanyURL.get(`/projects/getProjectByid/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getOverallProgress = async (id) => {
  try {
    const res = await CompanyURL.get(`/progressStage/getOverallProgress/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getTaskCountEachColumn = async (id) => {
  try {
    const res = await CompanyURL.get(
      `/progressStage/taskWithProgressStagesAndCount/${id}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersInProject = async (id) => {
  try {
    const res = await CompanyURL.get(`/projectUser/users/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDetails = async (id) => {
  try {
    const res = await CompanyURL.get(`/users/getUserCompany`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const sendInvitation = async (requestBody) => {
  try {
    const res = await CompanyURL.post(`/company/sendInvitation`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersInCompany = async () => {
  try {
    const res = await CompanyURL.get(`/users/getAllUsers`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (id, requestBody) => {
  try {
    const res = await CompanyURL.put(
      `/company/updatecompany/${id}`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

//? board Apis
export const taskWithProgressStages = async (projectId) => {
  try {
    const res = await baseURL.get(
      `progressStage/taskWithProgressStages/${projectId}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRequirements = async (id) => {
  try {
    const res = await CompanyURL.get(`/requirements/getAll/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const createProgressStage = async ({
  name,
  listIndex,
  type,
  project_id,
}) => {
  try {
    const res = await baseURL.post(`progressStage/createProgressStage`, {
      name,
      listIndex,
      type,
      project_id,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const moveList = async ({
  droppableIndexStart,
  droppableIndexEnd,
  projectId,
}) => {
  try {
    const res = await baseURL.post(`progressStage/moveList`, {
      droppableIndexStart,
      droppableIndexEnd,
      projectId,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateProgressStage = async ({ id, name, project_id, type }) => {
  try {
    const res = await baseURL.put(`progressStage/updateProgressStage`, {
      id,
      name,
      project_id,
      type,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteProgressStage = async ({ id }) => {
  try {
    const res = await baseURL.delete(
      `progressStage/deleteProgressStageById/${id}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllTasksOfProject = async (id) => {
  try {
    const res = await baseURL.get(`progressStage/getAllTasksOfProject/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getUsersOfProject = async (id) => {
  try {
    const res = await baseURL.get(`projectUser/users/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const createTask = async (formData) => {
  try {
    const res = await multipartAPI.post(`task/createTask`, formData);
    return res;
  } catch (error) {
    throw error;
  }
};

export const moveCardSameList = async ({
  droppableIndexStart,
  droppableIndexEnd,
  progressStage_id,
}) => {
  try {
    const res = await baseURL.post(`task/moveCardSameList`, {
      droppableIndexStart,
      droppableIndexEnd,
      progressStage_id,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const moveCard = async ({
  droppableIdStart,
  droppableIdEnd,
  droppableIndexStart,
  droppableIndexEnd,
  id,
}) => {
  try {
    const res = await baseURL.post(`task/moveCard`, {
      droppableIdStart,
      droppableIdEnd,
      droppableIndexStart,
      droppableIndexEnd,
      id,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteCard = async (id) => {
  try {
    const res = await baseURL.delete(`task/deleteTaskById/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getTaskById = async (id) => {
  try {
    const res = await baseURL.get(`task/getTaskById/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteAttachment = async (id) => {
  try {
    const res = await baseURL.delete(`task/deleteAttachmentById/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateTask = async (formData) => {
  try {
    const res = await multipartAPI.put(`task/updateTask`, formData);
    return res;
  } catch (error) {
    throw error;
  }
};

//Requirements
export const createRequirement = async (requestBody) => {
  try {
    const res = await multipartAPI.post(`/requirements/add`, requestBody);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateRequirement = async (id, requestBody) => {
  try {
    const res = await multipartAPI.put(
      `/requirements/update/${id}`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteRequirement = async (id) => {
  try {
    const res = await CompanyURL.delete(`/requirements/delete/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

//Basic Setting
export const getAllProjects = async (id) => {
  try {
    const res = await CompanyURL.get(`/projects/getAllprojects/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllProjectsForDeveloper = async (id) => {
  try {
    const res = await CompanyURL.get(`/projectUser/projects/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (id, requestBody) => {
  try {
    const res = await CompanyURL.put(
      `/projects/updateProject/${id}`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (id) => {
  try {
    const res = await CompanyURL.delete(`/projects/deleteProject/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllDevelopersInProject = async (id) => {
  try {
    const res = await CompanyURL.get(`/projectUser/users/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const addDeveloprToProject = async (requestBody) => {
  try {
    const res = await CompanyURL.post(
      `/projectUser/addUserToProject`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const removeDeveloperFromProject = async (requestBody) => {
  try {
    const res = await CompanyURL.post(
      `/projectUser/removeUserFromProject`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const removeDeveloperFromCompany = async (requestBody) => {
  try {
    const res = await CompanyURL.post(
      `/users/removeUserFromCompany`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateProjectDeveloperRole = async (requestBody) => {
  try {
    const res = await CompanyURL.put(
      `/projectUser/updateUserProject`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getUserContribution = async (id) => {
  try {
    const res = await CompanyURL.get(
      `/progressStage/getUserContribution/${id}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

//profile

export const getUserDetails = async (id) => {
  try {
    const res = await CompanyURL.get(`/users/getUser/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserDetails = async (id, requestBody) => {
  try {
    const res = await CompanyURL.put(
      `/users/updateUserDetails/${id}`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserImage = async (id, requestBody) => {
  try {
    const res = await multipartAPI.put(
      `/users/updateUserImage/${id}`,
      requestBody
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getProjectUserRole = async (id) => {
  try {
    const res = await CompanyURL.get(`/projectUser/getUserRoleByProjectAndUser/${id}`);
    return res;
  } catch (error) {
    throw error;
  }
};

//notifications
export const getNotificationOfUser = async () => {
  try {
    const res = await baseURL.get(`notification/getNotificationOfUser`);
    return res;
  } catch (error) {
    throw error;
  }
};
export const seenUserNotifications = async () => {
  try {
    const res = await baseURL.put(`notification/seenUserNotifications`);
    return res;
  } catch (error) {
    throw error;
  }
};
import DetailCart from "./DetailCart";
import "./Progress.css";
import detail1 from "../../assets/images/detail1.png";
import detail2 from "../../assets/images/detail2.png";
import detail3 from "../../assets/images/detail3.png";
import detail4 from "../../assets/images/detail4.png";
import calender from "../../assets/images/calender.png";
import React, { useState, useEffect } from "react";
import {
  GetProjectByID,
  getAllUsersInProject,
  getOverallProgress,
} from "../../api/axios";
import { useProject } from "../../context/ProjectContext";

const Detail = () => {
  const [overAllProgress, setOverAllProgress] = useState(0);
  const [project, setProject] = useState({});
  const [companyUsers, setCompanyUsers] = useState({});
  const { selectedProjectId } = useProject();

  const GetOverallProgress = async (id) => {
    try {
      const data = await getOverallProgress(id);
      setOverAllProgress(data.data.result.result);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const getProjectById = async (id) => {
    try {
      const data = await GetProjectByID(id);
      setProject(data.data.project);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const GetAllUsersInProject = async (id) => {
    try {
      const data = await getAllUsersInProject(id);
      setCompanyUsers(data.data.users);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetOverallProgress(selectedProjectId);
      getProjectById(selectedProjectId);
      GetAllUsersInProject(selectedProjectId);
    }
  }, [selectedProjectId]);

  const incompleteTaskCount =
    overAllProgress.taskCount - overAllProgress.doneCount;

  return (
    <div className="detail-container">
      <div className="details">
        <DetailCart
          image={detail1}
          color={"#FB9E42"}
          value={overAllProgress.taskCount ? overAllProgress.taskCount : 0}
          text={"Total Task"}
        />
        <DetailCart
          image={detail2}
          color={"#02B7AE"}
          value={incompleteTaskCount ? incompleteTaskCount : 0}
          text={"Incomplete"}
        />
        <DetailCart
          image={detail3}
          color={"#B982FF"}
          value={overAllProgress.doneCount ? overAllProgress.doneCount : 0}
          text={"Done"}
        />
        <DetailCart
          image={detail4}
          color={"#F76E97"}
          value={companyUsers.length ? companyUsers?.length : 0}
          text={"Members"}
        />
        <div className="end-date">
          <img src={calender} className="calender" alt="" />
          <div>
            <span>End Date: </span>
            <span>{project.endDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;

import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { CreateProject, updateProject } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";

const ProjectCreateModal = ({
  show,
  setShow,
  title,
  companyId,
  item,
  fetchProjectData,
}) => {
  const initialState = {
    projectName: "",
    startDate: "",
    endDate: "",
    description: "",
  };
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [minEndDate, setMinEndDate] = useState("");

  useEffect(() => {
    if (title === "create") {
      setFormData(initialState);
      setMinEndDate("");
    } else {
      setFormData({
        projectName: item.projectName,
        startDate: item.startDate,
        endDate: item.endDate,
        description: item.description,
      });
    }
  }, [title, item, show, setShow]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({ ...formData, [name]: value });

    if (name === "startDate") {
      setMinEndDate(value);
      if (new Date(formData.endDate) < new Date(value)) {
        setFormData({
          ...formData,
          startDate: value,
          endDate: "",
        });
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.projectName.trim()) {
      errors.projectName = "Project name is required";
    }
    if (!formData.startDate.trim()) {
      errors.startDate = "Start date is required";
    }
    if (!formData.endDate.trim()) {
      errors.endDate = "End date is required";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      if (title === "create") {
        try {
          const requestBody = {
            companyId: companyId,
            projectName: formData.projectName,
            startDate: formData.startDate,
            endDate: formData.endDate,
            description: formData.description,
          };

          const res = await CreateProject(requestBody);
          if (res.data.success) {
            CustomToast.success("Project create successfull!");
            setShow(!show);
            fetchProjectData();
            handleClose();
          } else {
            CustomToast.error("Project add failed!");
          }
        } catch (error) {
          console.log("Error create project", error);
          CustomToast.error(error.response.data.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const updateReqBody = {
            projectName: formData.projectName,
            startDate: formData.startDate,
            endDate: formData.endDate,
            description: formData.description,
          };

          const res = await updateProject(item.projectId, updateReqBody);
          if (res.data.success) {
            CustomToast.success("Project update successfull!");
            setShow(!show);
            fetchProjectData();
            handleClose();
          } else {
            CustomToast.error("Project update failed!");
          }
        } catch (error) {
          console.log("Error update project", error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}
            >
              {title === "create" ? "Add New Project" : "Update Project"}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Project Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="projectName"
                  name="projectName"
                  type="text"
                  label="Enter a project name"
                  variant="filled"
                  value={formData.projectName}
                  onChange={handleInputChange}
                  error={!!validationErrors.projectName}
                />
                {validationErrors.projectName && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.projectName}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Start Date
                </InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  name="startDate"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleInputChange}
                  value={formData.startDate}
                  error={!!validationErrors.startDate}
                />
                {validationErrors.startDate && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.startDate}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  End Date
                </InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  id="endDate"
                  type="date"
                  name="endDate"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.endDate}
                  inputProps={{ min: minEndDate }}
                  onChange={handleInputChange}
                  error={!!validationErrors.endDate}
                />
                {validationErrors.endDate && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.endDate}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Description
                </InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  type="text"
                  label="Enter description"
                  variant="filled"
                  multiline
                  rows={5}
                  value={formData.description}
                  onChange={handleInputChange}
                  error={!!validationErrors.description}
                />
                {validationErrors.description && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.description}
                  </FormHelperText>
                )}
              </Box>
              <Box display="flex" justifyContent="right" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    padding: "8px 40px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProjectCreateModal;

import React, { useState, useEffect } from "react";
import "./Requirement.css";
import { Box, Typography, Button } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import RequirementCard from "./RequirementCard";
import RequirementCreateModal from "./RequirementCreateModal";
import { useProject } from "../../context/ProjectContext";
import { getAllRequirements, getProjectUserRole } from "../../api/axios";
import { jwtDecode } from "jwt-decode";

const Requirements = () => {
  const [show, setShow] = useState(false);
  const { selectedProjectId } = useProject();
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(false);
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);
  const [projectRole, setProjectRole] = useState("");

  const GetAllRequirements = async (id) => {
    setLoading(true);
    try {
      const data = await getAllRequirements(id);
      setRequirements(data.data.requirements);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const GetProjectUserRole = async (id) => {
    try {
      const data = await getProjectUserRole(id);
      setProjectRole(data.data.result.result);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetAllRequirements(selectedProjectId);
      GetProjectUserRole(selectedProjectId);
    }
  }, [selectedProjectId]);

  const handleClickAddRequirement = () => {
    setShow(true);
  };

  return (
    <div className="requirement-container">
      <div className="requirements">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          >
            Requirements
          </Typography>
          {(decodedToken?.jobTitle === "System_Admin" ||
            projectRole?.role === "Project_Manager" ||
            projectRole?.role === "Business_Analyst") && (
            <Button
              sx={{
                fontFamily: "'Poppins', sans-serif",
                padding: "10px 30px",
                backgroundColor: "#342352",
                color: "white",
                textTransform: "capitalize",
                fontSize: "14px",
                borderRadius: "8px",
                gap: "5px",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
              }}
              onClick={handleClickAddRequirement}
            >
              <FaPlus />
              Add New Requirement
            </Button>
          )}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
          }}
        >
          {loading ? (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              Loading...
            </span>
          ) : requirements.length > 0 ? (
            requirements.map((item) => (
              <RequirementCard
                key={item.requirementId}
                item={item}
                projectID={selectedProjectId}
                GetAllRequirements={GetAllRequirements}
                projectRole={projectRole}
              />
            ))
          ) : (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              No Requirements!
            </span>
          )}
        </Box>
      </div>
      <RequirementCreateModal
        show={show}
        setShow={setShow}
        title={"create"}
        projectID={selectedProjectId}
        GetAllRequirements={GetAllRequirements}
      />
    </div>
  );
};

export default Requirements;

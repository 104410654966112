import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { createRequirement, updateRequirement } from "../../api/axios";
import CustomToast from "../CustomToast/CustomToast";

const RequirementCreateModal = ({
  show,
  setShow,
  title,
  projectID,
  GetAllRequirements,
  item,
}) => {
  const initialState = {
    topic: "",
    description: "",
    file: null,
  };
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (title === "create") {
      setFormData(initialState);
    } else {
      setFormData({
        topic: item.title,
        description: item.description,
        file: item.attachment,
      });
    }
  }, [title, item, show, setShow]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];

      setFormData({
        ...formData,
        [name]: selectedFile,
      });
    } else {
      setFormData({
        ...formData,
        [name]: formData.file,
      });
    }

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.topic.trim()) {
      errors.topic = "Topic is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      if (title === "create") {
        try {
          const requestBody = {
            title: formData.topic,
            description: formData.description,
            file: formData.file,
            projectId: projectID,
          };

          const res = await createRequirement(requestBody);
          if (res.data.success) {
            CustomToast.success(res.data.message);
            setShow(!show);
            GetAllRequirements(projectID);
            handleClose();
          } else {
            CustomToast.error("Requirement add failed!");
          }
        } catch (error) {
          console.log("Error create requirement", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const updateReqBody = {
            title: formData.topic,
            description: formData.description,
            file: formData.file,
          };

          const res = await updateRequirement(
            item.requirementId,
            updateReqBody
          );
          if (res.data.success) {
            CustomToast.success("Requirement update successfull!");
            setShow(!show);
            GetAllRequirements(projectID);
            handleClose();
          } else {
            CustomToast.error("Requirement update failed!");
          }
        } catch (error) {
          console.log("Error update requirement", error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}
            >
              {title === "create"
                ? "Add New Requirement"
                : "Update Requirement"}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Topic
                </InputLabel>
                <TextField
                  fullWidth
                  id="topic"
                  name="topic"
                  type="text"
                  label="Enter a topic"
                  variant="filled"
                  value={formData.topic}
                  onChange={handleInputChange}
                  error={!!validationErrors.topic}
                />
                {validationErrors.topic && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.topic}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Description
                </InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  type="text"
                  label="Enter description"
                  variant="filled"
                  multiline
                  rows={5}
                  value={formData.description}
                  onChange={handleInputChange}
                  error={!!validationErrors.description}
                />
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="outlined-adornment-name"
                >
                  Upload Attachment
                </InputLabel>
                <TextField
                  fullWidth
                  label=" "
                  name="file"
                  inputProps={{
                    accept: "image/*,video/*,application/pdf,application/zip",
                  }}
                  onChange={handleFileChange}
                  variant="filled"
                  type="file"
                  error={!!validationErrors.file}
                />
                {validationErrors.file && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {validationErrors.file}
                  </FormHelperText>
                )}
                {formData.file && (
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "0",
                      fontWeight: "bold",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Selected File:
                    {formData.file.name}
                  </p>
                )}
              </Box>
              <Box display="flex" justifyContent="right" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    padding: "8px 40px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequirementCreateModal;

import { Button } from "@mui/material";
import React, { useState } from "react";
import pdfLogo from "./../../assets/images/pdf.png";
import zipLogo from "./../../assets/images/Zip.png";
import fileLogo from "./../../assets/images/file.png";
import Swal from "sweetalert2";
import { Delete, Download } from "@mui/icons-material";
import AttachmentFullSizeView from "./AttachmentFullSizeView";
import { deleteAttachment } from "../../api/axios";

const TaskAttachmentView = ({ file, showDeleteButton, setFormData, task }) => {
  const [showFullView, setShowFullView] = useState(false);
  const imageTypes = ["png", "jpg", "jpeg", "gif", "bmp", "webp"];
  const videoTypes = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm", "mpeg"];

  const attachmentView = () => {
    const fileType = getFileTypeFromName(file?.name);
    if (imageTypes.includes(fileType)) {
      return (
        <div>
          <img
            style={{ width: "50px", height: "50px", cursor: "all-scroll" }}
            src={file.link}
            alt=""
            onClick={() => setShowFullView(true)}
          />
        </div>
      );
    }
    if (videoTypes.includes(fileType)) {
      return (
        <div>
          <video
            style={{ width: "50px", height: "50px", cursor: "all-scroll" }}
            src={file.link}
            muted
            onClick={() => setShowFullView(true)}
          />
        </div>
      );
    }
    if (fileType === "pdf") {
      return (
        <div>
          <img style={{ width: "50px", height: "50px" }} src={pdfLogo} alt="" />
        </div>
      );
    }
    if (fileType === "zip") {
      return (
        <div>
          <img style={{ width: "50px", height: "50px" }} src={zipLogo} alt="" />
        </div>
      );
    }
    return (
      <div>
        <img
          style={{ width: "50px", height: "50px", cursor: "pointer" }}
          src={fileLogo}
          alt=""
          onClick={() => setShowFullView(true)}
        />
      </div>
    );
  };

  const getFileTypeFromName = (name) => {
    if (typeof name === "string" && name.includes(".")) {
      const parts = name.split(".");
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  };

  const handleDownload = (url, filename) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const deleteAttachmentHandler = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#231541",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("confirm");
          await deleteAttachment(file.id)
            .then((result) => {
              console.log(result.data);
              if (result.data.success) {
                setFormData((prev) => {
                  return {
                    ...prev,
                    file: prev.file.filter(
                      (attachment) => attachment.id !== file.id
                    ),
                  };
                });
              }
            })
            .catch((error) => console.log(error));
        }
      });
    } catch (error) {}
  };

  return (
    <div>
      {showFullView && (
        <div>
          <AttachmentFullSizeView
            getFileTypeFromName={getFileTypeFromName}
            file={file}
            setShowFullView={setShowFullView}
          />
        </div>
      )}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ maxHeight: "55px" }}
      >
        <div className="d-flex align-items-center">
          <img src="./../../assets/images/member.jpg" alt="" />
          {attachmentView()}
          <div
            style={{
              marginLeft: "4px",
              fontSize: "15px",
              maxWidth: `${showDeleteButton ? "170px" : "300px"}`,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "'Poppins', sans-serif",
              color: "#AFAAAA",
            }}
          >
            {file?.name}
          </div>
        </div>
        <div className=" gap-1 d-flex">
          <Button
            onClick={() => handleDownload(file.link, file.name)}
            type="button"
            variant="contained"
            sx={{
              backgroundColor: "#A764FE",
              color: "white",
              height: "30px",
              width: "30px",
              fontSize: "16px",
              borderRadius: "6px",
              fontFamily: "'Poppins', sans-serif",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#9750f4",
              },
            }}
          >
            <Download />
          </Button>
          {showDeleteButton && (
            <Button
              onClick={deleteAttachmentHandler}
              type="button"
              variant="contained"
              sx={{
                backgroundColor: "#E34545",
                color: "white",
                height: "30px",
                width: "30px",
                fontSize: "16px",
                borderRadius: "6px",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "purple",
                },
              }}
            >
              <Delete />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskAttachmentView;

import React from "react";
import Requirements from "../../../components/Requirement/Requirements";


const Requirement = () => {
  return (
    <div>
      <Requirements />
    </div>
  );
};

export default Requirement;

import React from "react";
import Companysettings from "../../../components/CompanyModal/Companysettings";
import Member from "../../../components/MemberModal/Member";
import Project from "../../../components/ProjectModal/Project";

const Timeline = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Companysettings />
      <Project />
      <Member />
    </div>
  );
};

export default Timeline;

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MemberCart from "./MemberCart";
import { useProject } from "../../context/ProjectContext";
import { getAllDevelopersInProject } from "../../api/axios";
import SearchBar from "./SearchBar";
import { jwtDecode } from "jwt-decode";

const MemberSetting = () => {
  const { selectedProjectId } = useProject();
  const [projectUses, setProjectUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  const GetAllDevelopersInProject = async (id) => {
    setLoading(true);
    try {
      const res = await getAllDevelopersInProject(id);
      setProjectUsers(res.data.users);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetAllDevelopersInProject(selectedProjectId);
    }
  }, [selectedProjectId]);

  return (
    <div className="project-setting-container">
      <Typography
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "600",
          fontSize: "24px",
          color: "#342352",
        }}
      >
        Member Settings
      </Typography>
      <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {decodedToken?.jobTitle === "System_Admin" && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "500",
                fontSize: "16px",
                color: "#9D9D9D",
              }}
            >
              Add new member :
            </Typography>
            <SearchBar GetAllDevelopersInProject={GetAllDevelopersInProject} />
          </Box>
        )}

        <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          {loading ? (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              Loading...
            </span>
          ) : projectUses.length > 0 ? (
            projectUses.map((item) => (
              <MemberCart
                key={item.user_id}
                item={item}
                selectedProjectId={selectedProjectId}
                GetAllDevelopersInProject={GetAllDevelopersInProject}
              />
            ))
          ) : (
            <span
              style={{ fontSize: "16px", textAlign: "left", width: "100%" }}
            >
              No Members!
            </span>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MemberSetting;

import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedCompanyLogin = ({ children }) => {
    const token = localStorage.getItem("token") || localStorage.getItem("tokenCompany");
    let hasCompany = false;
    if (token) {
    try {
    const decodedToken = jwtDecode(token);
    hasCompany = decodedToken.hasCompany;
    } catch (error) {
    console.error("Invalid token", error);
    }
    }

    const auth = token && hasCompany === false ? true : false;

    return auth ? children : <Navigate to={'/'}/>;
};

export default ProtectedCompanyLogin;
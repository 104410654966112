import React, { useState, useEffect } from "react";
import "./Setting.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  getAllDevelopersInProject,
  getAllUsersInCompany,
} from "../../api/axios";
import { useProject } from "../../context/ProjectContext";
import MemberAddModal from "./MemberAddModal";

const SearchBar = ({ GetAllDevelopersInProject }) => {
  const { selectedProjectId } = useProject();
  const [input, setInput] = useState("");
  const [companyMembers, setCompanyMembers] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const GetAllMembers = async () => {
    try {
      const res = await getAllUsersInCompany();
      setCompanyMembers(res.data.users);
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };

  useEffect(() => {
    GetAllMembers();
  }, [show, setShow]);

  const GetAllMembersInProject = async (id) => {
    try {
      const res = await getAllDevelopersInProject(id);
      setProjectMembers(res.data.users);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const filterdMembers = companyMembers.filter(
    (companyMember) =>
      !projectMembers.some(
        (projectMember) => projectMember.user_id === companyMember.id
      )
  );

  useEffect(() => {
    if (selectedProjectId) {
      GetAllMembersInProject(selectedProjectId);
    }
  }, [selectedProjectId, show, setShow]);

  const handleSearch = (value) => {
    if (value) {
      const results = filterdMembers.filter((item) =>
        `${item.firstName} ${item.lastName}`
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleChange = (value) => {
    setInput(value);
    handleSearch(value);
  };

  const handleClick = (item) => {
    setSelectedMember(item);
    setSearchResults([]);
    setInput("");
    setShow(true);
  };

  return (
    <div className="searchbar-container">
      <div className="input-wrapper">
        <SearchIcon sx={{ color: "#9D9D9D" }} />
        <input
          className="search-input"
          placeholder="Search member.."
          value={input}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </div>
      <div className="serach-result-wrapper">
        {input && searchResults.length === 0 ? (
          <div className="search-result">User not found</div>
        ) : (
          searchResults.map((item) => (
            <div
              className="search-result"
              key={item.id}
              onClick={() => {
                handleClick(item);
              }}
            >
              {item.firstName} {item.lastName}
            </div>
          ))
        )}
      </div>
      <MemberAddModal
        show={show}
        setShow={setShow}
        item={selectedMember}
        projectId={selectedProjectId}
        GetAllDevelopersInProject={GetAllDevelopersInProject}
      />
    </div>
  );
};

export default SearchBar;

import "./Board.css";
import StatusColumn from "./StatusColumn";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ColumnAddModal from "./ColumnAddModal";
import { useProject } from "../../context/ProjectContext";
import {
  taskWithProgressStages,
  moveList,
  moveCardSameList,
  moveCard,
  getProjectUserRole,
} from "./../../api/axios";
import { jwtDecode } from "jwt-decode";
import { Box, Typography } from "@mui/material";
import TotalAvatars from "./TotalAvatars";

export const ColumnDataContext = React.createContext();

const StatusGrid = () => {
  const [columnData, setColumnData] = useState([]);
  const { selectedProjectId } = useProject();
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);
  const [projectRole, setProjectRole] = useState("");

  useEffect(() => {
    if (selectedProjectId) {
      getPsData(selectedProjectId);
      GetProjectUserRole(selectedProjectId);
    }
  }, [selectedProjectId]);

  const GetProjectUserRole = async (id) => {
    try {
      const data = await getProjectUserRole(id);
      setProjectRole(data.data.result.result);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const getPsData = async () => {
    try {
      const response = await taskWithProgressStages(selectedProjectId);
      if (response.data?.result?.success) {
        setColumnData(response.data.result.result);

        // console.log("columnData",response.data.result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createProgressStage = async ({ name, listIndex, type, project_id }) => {
    try {
      const response = await createProgressStage({
        name,
        listIndex,
        type,
        project_id,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClickAddColumn = () => {
    setShow(true);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (result.type === "list") {
      const response = await moveList({
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        projectId: selectedProjectId,
      });
      if (response.data.result.success) {
        setColumnData((prev) => {
          const newColumnData = Array.from(prev);
          const [movedColumn] = newColumnData.splice(source.index, 1);
          newColumnData.splice(destination.index, 0, movedColumn);
          return newColumnData;
        });
      }
      console.log(response.data.result.success);
    }
    console.log(result);
    if (destination.droppableId === source.droppableId) {
      console.log(result);
      const response = await moveCardSameList({
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        progressStage_id: destination.droppableId,
      });
      if (response.data.result.success) {
        setColumnData((prev) => {
          return prev.map((ps) => {
            if (ps.id === destination.droppableId) {
              const newTasks = [...ps.tasks];
              const [elementRemoved] = newTasks.splice(source.index, 1);
              newTasks.splice(destination.index, 0, elementRemoved);
              return {
                ...ps,
                tasks: newTasks,
              };
            }
            return ps;
          });
        });
      }
      console.log(response.data.result.success);
    } else {
      console.log("cc");
      const response = await moveCard({
        droppableIdStart: source.droppableId,
        droppableIdEnd: destination.droppableId,
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        id: draggableId,
      });
      if (response.data.result.success) {
        setColumnData((prev) => {
          let elementRemoved;
          const updatedColumns = prev
            .map((ps) => {
              if (ps.id === source.droppableId) {
                const newTasks = [...ps.tasks];
                elementRemoved = newTasks.splice(source.index, 1)[0];
                return {
                  ...ps,
                  tasks: newTasks,
                };
              }
              return ps;
            })
            .map((ps) => {
              if (ps.id === destination.droppableId) {
                const newTasks = [...ps.tasks];
                newTasks.splice(destination.index, 0, elementRemoved);
                return {
                  ...ps,
                  tasks: newTasks,
                };
              }
              return ps;
            });
          return updatedColumns;
        });
      }
      console.log(response.data.result.success);
    }
  };
  return (
    <>
    {
      columnData.length > 0 &&
    <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "max-content",
      padding: "20px",
      height: "50px",
      backgroundColor: "#fff",
      margin: "20px 30px",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
    }}
    >
      <Typography variant="h6" sx={{mr:2}}>
        Filters:
      </Typography>
      <Box sx={{cursor:'pointer'}}>
        <TotalAvatars />
      </Box>
    </Box>
    }
      <ColumnDataContext.Provider value={{ columnData, setColumnData }}>
        <div className="status-grid-container">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="all-lists"
              direction="horizontal"
              type="list"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="status-grid">
                    {columnData.map((item, index) => (
                      <StatusColumn key={item.id} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {(decodedToken?.jobTitle === "System_Admin" ||
            projectRole?.role === "Project_Manager" ||
            projectRole?.role === "Business_Analyst") && (
            <div className="column-add-icon" onClick={handleClickAddColumn}>
              <AddIcon />
            </div>
          )}
          <ColumnAddModal
            show={show}
            setShow={setShow}
            columnData={columnData}
            setColumnData={setColumnData}
          />
        </div>
      </ColumnDataContext.Provider>
    </>
  );
};
export default StatusGrid;

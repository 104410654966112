import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { startOfDay } from "date-fns";
import { ColumnDataContext } from "./StatusGrid";
import { useProject } from "../../context/ProjectContext";
import { jwtDecode } from "jwt-decode";
import { getUserDetails } from "../../api/axios";
import {
  getAllTasksOfProject,
  getUsersOfProject,
  createTask,
} from "./../../api/axios";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import "./Board.css";
import CustomToast from "../CustomToast/CustomToast";

const IssueCreateModal = ({ show, setShow, item }) => {
  const [formData, setFormData] = useState({
    taskName: "",
    flag: "",
    assignee: "",
    reporter: "",
    startDate: null,
    endDate: null,
    link: "",
    description: "",
    file: [],
  });
  const { selectedProjectId } = useProject();
  const [allTasksOfProject, setAllTasksOfProject] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const { setColumnData } = useContext(ColumnDataContext);
  const [usersOfTheProject, setUsersOfTheProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const token =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(token);

  useEffect(() => {
    GetUserDetails(decodedToken.userId);
  }, [decodedToken.userId]);

  const GetUserDetails = async (id) => {
    try {
      const res = await getUserDetails(id);
      if (res.data.success) {
        setUser(res.data.user);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const me = usersOfTheProject.filter(
      (userData) => userData.user_id === user.id
    );
    if (me.length > 0) {
      setFormData({ ...formData, reporter: user.id });
    }
  }, [user, usersOfTheProject]);

  const getAllTasksOfProjectHandler = async () => {
    try {
      const response = await getAllTasksOfProject(selectedProjectId);
      if (response.data.result.success) {
        setAllTasksOfProject(response.data.result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersOfProjectHandler = async () => {
    try {
      const response = await getUsersOfProject(selectedProjectId);
      if (response.data.success) {
        setUsersOfTheProject(response.data.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      getAllTasksOfProjectHandler();
      getUsersOfProjectHandler();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
    setFormData({
      taskName: "",
      flag: "",
      assignee: "",
      reporter: "",
      startDate: null,
      endDate: null,
      link: "",
      description: "",
      file: [],
    });
  };

  const validationSchema = yup.object().shape({
    taskName: yup.string().required("Task Name is required"),
    flag: yup.string().required("Flag is required"),
    assignee: yup.string().required("Assignee is required"),
    reporter: yup.string().required("Reporter is required"),
    startDate: yup
      .date()
      .nullable()
      .min(startOfDay(new Date()), "Start Date must be today or in the future")
      .required("Start Date is required"),
    endDate: yup.date().nullable().required("End Date is required"),
    link: yup.string(),
    description: yup.string(),
    file: yup.mixed(),
  });

  const validateField = (field, value) => {
    if (
      field === "endDate" &&
      formData.startDate !== null &&
      value < formData.startDate
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "The end date must be later than the start date",
      }));
      return;
    }
    validationSchema
      .validateAt(field, { ...formData, [field]: value })
      .then(() => {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [field]: error.message,
        }));
      });
  };

  const handleFieldChange = (field, value) => {
    if (field === "file") {
      const filesArray = Array.from(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: filesArray,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value,
      }));
    }
    validateField(field, value);
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (errors) {
      const formattedErrors = {};
      errors.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });
      setValidationErrors(formattedErrors);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (await validateForm()) {
      if (formData.startDate > formData.endDate) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "The end date must be later than the start date",
        }));
        return;
      }

      let taskIdex = 0;
      if (item.tasks.length > 0) {
        taskIdex = item.tasks[item.tasks.length - 1].taskIndex + 1;
      }

      const taskFormData = new FormData();
      taskFormData.append("name", formData.taskName);
      taskFormData.append("flag", formData.flag);
      taskFormData.append("startDate", formData.startDate);
      taskFormData.append("endDate", formData.endDate);
      taskFormData.append("description", formData.description);
      taskFormData.append("taskIndex", taskIdex);
      taskFormData.append("assign", formData.assignee);
      taskFormData.append("reporter", formData.reporter);
      taskFormData.append("progressStage_id", item.id);
      if (formData.link) {
        taskFormData.append("linked_task_id", formData.link);
      }

      formData.file.forEach((file) => {
        taskFormData.append("attachment", file);
      });

      try {
        setIsLoading(true);
        const response = await createTask(taskFormData);
        if (response.data.result.status) {
          setFormData({
            taskName: "",
            flag: "",
            assignee: "",
            reporter: "",
            startDate: null,
            endDate: null,
            link: "",
            description: "",
            file: [],
          });
          setColumnData((prev) =>
            prev.map((column) => {
              if (column.id === item.id) {
                return {
                  ...column,
                  tasks: [...column.tasks, response.data.result.result],
                };
              }
              return column;
            })
          );
          setShow(false);
          CustomToast.success("Issue create successfull!")
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}
            >
              Create Task
            </Typography>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name"
                  >
                    Task Name
                  </InputLabel>
                  <TextField
                    id="taskName"
                    name="taskName"
                    type="text"
                    label="Enter a task name"
                    variant="outlined"
                    value={formData.taskName}
                    onChange={(e) =>
                      handleFieldChange("taskName", e.target.value)
                    }
                    error={!!validationErrors.taskName}
                  />
                  {validationErrors.taskName && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.taskName}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Select a flag
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select a flag</InputLabel>
                    <Select
                      id="flag"
                      value={formData.flag}
                      label="Select a flag"
                      onChange={(e) =>
                        handleFieldChange("flag", e.target.value)
                      }
                      error={!!validationErrors.flag}
                    >
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="hard">Hard</MenuItem>
                    </Select>
                    {validationErrors.flag && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}
                      >
                        {validationErrors.flag}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Assignee
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="assignee"
                      label="Select an Option"
                      value={formData.assignee}
                      onChange={(e) =>
                        handleFieldChange("assignee", e.target.value)
                      }
                      error={!!validationErrors.assignee}
                    >
                      {usersOfTheProject.map((user, index) => (
                        <MenuItem key={index} value={user.user_id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.assignee && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}
                      >
                        {validationErrors.assignee}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Reporter
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="reporter"
                      value={formData.reporter}
                      label="Select an Option"
                      onChange={(e) =>
                        handleFieldChange("reporter", e.target.value)
                      }
                      error={!!validationErrors.reporter}
                    >
                      {usersOfTheProject.map((user, index) => (
                        <MenuItem key={index} value={user.user_id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.reporter && (
                      <FormHelperText
                        style={{ color: "#f44336", margin: "3px 0 0" }}
                      >
                        {validationErrors.reporter}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name"
                  >
                    Start Date
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="startDate"
                    name="startDate"
                    label=" "
                    type="date"
                    variant="outlined"
                    value={formData.startDate}
                    onChange={(e) =>
                      handleFieldChange("startDate", e.target.value)
                    }
                    error={!!validationErrors.startDate}
                  />
                  {validationErrors.startDate && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.startDate}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name"
                  >
                    End Date
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="endDate"
                    label=" "
                    name="endDate"
                    type="date"
                    variant="outlined"
                    value={formData.endDate}
                    onChange={(e) =>
                      handleFieldChange("endDate", e.target.value)
                    }
                    error={!!validationErrors.endDate}
                  />
                  {validationErrors.endDate && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.endDate}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "10px",
                }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Link
                  </InputLabel>
                  <FormControl fullWidth>
                    <InputLabel>Select an Option</InputLabel>
                    <Select
                      id="link"
                      value={formData.link}
                      label="Select an Option"
                      onChange={(e) =>
                        handleFieldChange("link", e.target.value)
                      }
                      error={!!validationErrors.link}
                    >
                      {allTasksOfProject.map((task) => (
                        <MenuItem key={task.id} value={task.id}>
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.link && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.link}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name"
                  >
                    Description
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    type="text"
                    label="Enter Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                    error={!!validationErrors.description}
                  />
                  {validationErrors.description && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.description}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="outlined-adornment-name"
                  >
                    Upload Attachment
                  </InputLabel>
                  <TextField
                    fullWidth
                    label=" "
                    name="file"
                    inputProps={{
                      multiple: true,
                      accept: "image/*,video/*,application/pdf,application/zip",
                    }}
                    onChange={(e) =>
                      handleFieldChange("file", e.currentTarget.files)
                    }
                    variant="outlined"
                    type="file"
                    error={!!validationErrors.file}
                  />
                  {validationErrors.file && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.file}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    width: "80%",
                    height: "40px",
                    fontSize: "16px",
                    borderRadius: "50px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IssueCreateModal;

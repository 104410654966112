import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import "./Company.css";
import { getCompanyDetails, updateCompany } from "../../api/axios";
import { jwtDecode } from "jwt-decode";
import CustomToast from "../CustomToast/CustomToast";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";

const Companysettings = () => {
  const initialState = {
    companyName: "",
    email: "",
    phoneNumber: "",
    companyAddress: "",
    companyKey: "",
  };
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const tokenCompany =
    localStorage.getItem("token") || localStorage.getItem("tokenCompany");
  const decodedToken = jwtDecode(tokenCompany);

  const GetCompanyDetails = async () => {
    try {
      const res = await getCompanyDetails();
      setFormData({
        companyName: res.data.userCompany.companyname,
        email: res.data.userCompany.companyemail,
        phoneNumber: res.data.userCompany.contactnumber,
        companyAddress: res.data.userCompany.companyaddress,
        companyKey: res.data.userCompany.companyKey,
      });
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    GetCompanyDetails();
  }, [show, setShow]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.companyName.trim()) {
      errors.companyName = "Company name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!validatePhoneNumber(formData.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }
    if (!formData.companyAddress.trim()) {
      errors.companyAddress = "Company address is required";
    }
    if (!formData.companyKey.trim()) {
      errors.companyKey = "Company key is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      try {
        const requestBody = {
          companyname: formData.companyName,
          companyemail: formData.email,
          contactnumber: formData.phoneNumber,
          companyaddress: formData.companyAddress,
          companyKey: formData.companyKey,
        };

        const res = await updateCompany(decodedToken.companyId, requestBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          handleClose();
          window.location.reload();
        } else {
          CustomToast.error("Company details update failed!");
        }
      } catch (error) {
        console.error("Error updating company details", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="companysettings">
      <div className="company-settings">
        {decodedToken?.jobTitle === "System_Admin" ? (
          <div className="company-modal">
            <button className="company-modal-button" onClick={handleShow}>
              <FaCog />
              Company Settings
            </button>
          </div>
        ) : null}
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "24px",
                  }}
                >
                  Change Company Details
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: 15,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      htmlFor="outlined-adornment-name"
                    >
                      Company Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="companyName"
                      name="companyName"
                      type="text"
                      label=""
                      variant="filled"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      error={!!validationErrors.companyName}
                    />
                    {validationErrors.companyName && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.companyName}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: 15,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      htmlFor="outlined-adornment-name"
                    >
                      Email
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      name="email"
                      label=""
                      onChange={handleInputChange}
                      value={formData.email}
                      error={!!validationErrors.email}
                    />
                    {validationErrors.email && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.email}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: 15,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      htmlFor="outlined-adornment-name"
                    >
                      Phone Number
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="phoneNumber"
                      type="text"
                      name="phoneNumber"
                      label=""
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      error={!!validationErrors.phoneNumber}
                    />
                    {validationErrors.phoneNumber && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.phoneNumber}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: 15,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      htmlFor="outlined-adornment-name"
                    >
                      Company Address
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="companyAddress"
                      type="text"
                      name="companyAddress"
                      label=""
                      value={formData.companyAddress}
                      onChange={handleInputChange}
                      error={!!validationErrors.companyAddress}
                    />
                    {validationErrors.companyAddress && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.companyAddress}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontSize: 15,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      htmlFor="outlined-adornment-name"
                    >
                      Company Key
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="companyKey"
                      type="text"
                      name="companyKey"
                      label=""
                      value={formData.companyKey}
                      onChange={handleInputChange}
                      error={!!validationErrors.companyKey}
                    />
                    {validationErrors.companyKey && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        {validationErrors.companyKey}
                      </FormHelperText>
                    )}
                  </Box>

                  <Box display="flex" justifyContent="right" mt="20px">
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: "#231541",
                        color: "white",
                        padding: "8px 40px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        fontFamily: "'Poppins', sans-serif",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "purple",
                        },
                      }}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Companysettings;

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useProject } from '../../context/ProjectContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { getUserContribution } from '../../api/axios';
import { Box } from '@mui/material';
import { set } from 'date-fns';

export default function TotalAvatars() {
  const colorArray = [ "#ed0c22","#35db3a","blue","#ed6826","#c542d4","pink","#5c4b44","black","#039e08","yellow"]; 
  const { selectedProjectId,memberID,setMemberID } = useProject();
  const [membertData, setmemberData] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetUserContribution = async (id) => {
    setLoading(true);
    try {
      const res = await getUserContribution(id);
      const transformedResponse = transformApiResponse(res.data);
      setmemberData(transformedResponse.groupedTasksArray);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetUserContribution(selectedProjectId);
    }
    
  }, [selectedProjectId]);

  const transformApiResponse = (apiResponse) => {
    const groupedTasksArray = apiResponse.result.result.groupedTasksArray;
    const transformedArray = groupedTasksArray.map((taskGroup) => {
      const user = taskGroup.user;
      return {
        id: user.id,
        imageURL: user.imageURL,
        firstName: user.firstName,
      };
    });
    return { groupedTasksArray: transformedArray };
  };
  
  const handleSelectMember = (id) => {
    if(memberID != null && memberID === id){
      setMemberID(null);
      return;
    }
    setMemberID(id);
  }
  const color = colorArray[Math.floor(Math.random() * 8) + 1];
  
  return (
    <AvatarGroup  max={membertData.length+1}>
      {
        membertData.map((member,index) => (
          <Box key={member.id} sx={{
            backgroundColor: member.id === memberID ? color : "white",
            borderRadius: "40%",
          }}>
          <Avatar alt={member.firstName} src={member.imageURL} onClick={()=>handleSelectMember(member.id)}/>
          </Box>
        ))
      }
    </AvatarGroup>
  );
}
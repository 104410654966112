import ProgressDoughnut from "./Doughnut";
import "./Progress.css";
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useProject } from "../../context/ProjectContext";
import MultipleValueDoughnut from "./MulipleValueDoughnut";
import {
  GetProjectByID,
  getOverallProgress,
  getTaskCountEachColumn,
} from "../../api/axios";

const transformData = (data) => {
  if (!data?.result?.result?.rows) return [];
  return data.result.result.rows
    .filter((row) => row.taskCount > 0)
    .map((row) => ({
      name: row.name,
      count: row.taskCount,
    }));
};

const Chart = () => {
  const [taskProgress, setTaskProgress] = useState();
  const [overAllProgress, setOverAllProgress] = useState(0);
  const [deadlineRemaining, setDeadlineRemaining] = useState(0);
  const { selectedProjectId } = useProject();

  const CalculateOverallProgress = (values) => {
    const totalCount = values.taskCount;
    const doneCount = values.doneCount;

    if (totalCount > 0) {
      const progress = (doneCount / totalCount) * 100;
      return progress;
    } else {
      return 0;
    }
  };

  const CalculateDeadlineRemaining = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    if (today < start) {
      return 100.0;
    }

    const totalDuration = end - start;
    const remainingDuration = end - today;

    if (totalDuration > 0) {
      const remainingPercentage = (remainingDuration / totalDuration) * 100;
      return Math.max(remainingPercentage, 0).toFixed(2);
    } else {
      return 0;
    }
  };

  const GetOverallProgress = async (id) => {
    try {
      const data = await getOverallProgress(id);
      const overAllProgressValue = CalculateOverallProgress(
        data.data.result.result
      );
      setOverAllProgress(overAllProgressValue);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const getProjectById = async (id) => {
    try {
      const data = await GetProjectByID(id);
      const project = data.data.project;
      const deadlinePercentage = CalculateDeadlineRemaining(
        project.startDate,
        project.endDate
      );
      setDeadlineRemaining(deadlinePercentage);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const GetTaskCountEachColumn = async (id) => {
    try {
      const data = await getTaskCountEachColumn(id);
      const transformedData = transformData(data.data);
      setTaskProgress(transformedData);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      GetOverallProgress(selectedProjectId);
      getProjectById(selectedProjectId);
      GetTaskCountEachColumn(selectedProjectId);
    }
  }, [selectedProjectId]);

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#66FF66",
  ];

  return (
    <div className="chart-container">
      <div className="charts">
        <Box className="chart">
          <Typography variant="h6" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            Task Progress
          </Typography>
          <MultipleValueDoughnut dataPoints={taskProgress} colors={colors} />
        </Box>
        <Box className="chart">
          <Typography variant="h6" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            Overall Progress
          </Typography>
          <ProgressDoughnut
            progress={overAllProgress.toFixed(2)}
            color="#FB9E42"
          />
        </Box>
        <Box className="chart">
          <Typography variant="h6" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            Deadline Remaining
          </Typography>
          <ProgressDoughnut progress={deadlineRemaining} color="#02B7AE" />
        </Box>
      </div>
    </div>
  );
};

export default Chart;

import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SamplePage from "./scenes/samplepage/index";
import { ColorModeContext, useMode } from "./theme";
import Layout from "./components/Layout";
import Login from "./scenes/Pages/Login&SignUp/Login";
import Progress from "./scenes/Pages/Progress/Progress";
import RegisterCompany from "./scenes/Pages/Login&SignUp/RegisterCompany";
import LoginCompany from "./scenes/Pages/Login&SignUp/LoginCompany";
import Register from "./scenes/Pages/Login&SignUp/Register";
import ForgotPassword from "./scenes/Pages/Login&SignUp/ForgotPassword";
import "bootstrap/dist/css/bootstrap.min.css";
import Timeline from "./scenes/Pages/Timeline/Timeline";
import Board from "./scenes/Pages/Board/Board";
import Requirement from "./scenes/Pages/Requirement/Requirement";
import Setting from "./scenes/Pages/BasicSetting/Setting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectRoutes from "./utils/ProtectedRoutes";
import RedirectAuthenticated from "./utils/RedirectAuthenticated ";
import ProtectedCompanySignup from "./utils/ProtectedCompanySignup";
import NotFound from "./scenes/Pages/NotFound/NotFound";
import ProtectedCompanyLogin from "./utils/ProtectedCompanyLogin";
import Profile from "./scenes/Pages/Profile/Profile";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={
                <RedirectAuthenticated>
                  <Login />
                </RedirectAuthenticated>
              }
            />
            <Route
              path="/register"
              element={
                <RedirectAuthenticated>
                  <Register />
                </RedirectAuthenticated>
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <RedirectAuthenticated>
                  <ForgotPassword />
                </RedirectAuthenticated>
              }
            />
            <Route
              path="/registerCompany"
              element={
                <ProtectedCompanySignup>
                  <RegisterCompany />
                </ProtectedCompanySignup>
              }
            />
            <Route
              path="/loginCompany"
              element={
                <ProtectedCompanyLogin>
                  <LoginCompany />
                </ProtectedCompanyLogin>
              }
            />
            <Route element={<ProtectRoutes />}>
              <Route
                path="/sample"
                element={
                  <Layout>
                    <SamplePage />
                  </Layout>
                }
              />
              <Route
                path="/progress"
                element={
                  <Layout>
                    <Progress />
                  </Layout>
                }
              />
              <Route
                path="/company"
                element={
                  <Layout>
                    <Timeline />
                  </Layout>
                }
              />
              <Route
                path="/board/:taskId"
                element={
                  <Layout>
                    <Board />
                  </Layout>
                }
              />
              <Route
                path="/board"
                element={
                  <Layout>
                    <Board />
                  </Layout>
                }
              />
              <Route
                path="/Requirement"
                element={
                  <Layout>
                    <Requirement />
                  </Layout>
                }
              />
              <Route
                path="/Settings"
                element={
                  <Layout>
                    <Setting />
                  </Layout>
                }
              />
              <Route
                path="/profile"
                element={
                  <Layout>
                    <Profile />
                  </Layout>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const RedirectAuthenticated = ({ children }) => {
    // const token = localStorage.getItem("token") || localStorage.getItem("tokenCompany");
    // const decodedToken = jwtDecode(token);
    const auth = (localStorage.getItem("token") || localStorage.getItem("tokenCompany")) && localStorage.getItem("status") && localStorage.getItem("hasCompany") === "true" ? true : false;

    return auth ? <Navigate to="/Company" /> : children;
};

export default RedirectAuthenticated;
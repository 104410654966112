import { Modal } from "react-bootstrap";
import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  FormControl,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomToast from "../CustomToast/CustomToast.jsx";
import { updateProgressStage } from "./../../api/axios.js";
import { ColumnDataContext } from "./StatusGrid.jsx";

const ColumnEditModal = ({ show, setShow, columnData }) => {
  const [formData, setFormData] = useState({
    name: columnData.name,
    type: columnData.type,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setColumnData } = useContext(ColumnDataContext);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = { ...formData, [name]: value };

    if (name === "type" && value !== "other") {
      updatedFormData.name = value === "toDo" ? "To Do" : "Done";
      setValidationErrors((prev) => {
        return { ...prev, name: "" };
      });
    }
    if (name === "type" && value === "other") {
      updatedFormData.name = "";
    }

    setFormData(updatedFormData);
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...validationErrors };
    if (name === "name" && formData.type === "other") {
      errors[name] = value.trim() ? "" : "Name is required";
    }
    setValidationErrors(errors);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      if (validateForm()) {
        setIsLoading(true);
        const response = await updateProgressStage({
          id: columnData.id,
          name: formData.name,
          type: formData.type,
          project_id: 1,
        });
        if (response.data.result.status) {
          setColumnData((prev) => {
            return prev.map((ps) => {
              if (ps.id === columnData.id) {
                return {
                  ...ps,
                  name: response.data.result.result.name,
                  type: response.data.result.result.type,
                };
              }
              return ps;
            });
          });
          CustomToast.success("Progress stage update successfull!");
        }
        setIsLoading(false);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      if (error.response.data.error)
        CustomToast.error(error.response.data.error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "24px",
              }}
            >
              Edit Progress Stage
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  htmlFor="type"
                >
                  Save as
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    value={formData.type}
                    onChange={handleInputChange}
                    row
                    name="type"
                  >
                    <FormControlLabel
                      value="toDo"
                      control={<Radio />}
                      label="To Do"
                      name="type"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="done"
                      control={<Radio />}
                      label="Done"
                      name="type"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                      name="type"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                  </RadioGroup>
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    To Do and Done are the main progress stages in a board.
                    Please select if this progress stage belongs to one of these
                    main types. This data is used to calculate project
                    analytics.
                  </div>
                </FormControl>
              </Box>
              {formData && formData.type === "other" && (
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1,
                      fontSize: 15,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    htmlFor="name"
                  >
                    Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    label="Enter a name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!validationErrors.name}
                  />
                  {validationErrors.name && (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {validationErrors.name}
                    </FormHelperText>
                  )}
                </Box>
              )}

              <Box display="flex" justifyContent="center" mt="20px">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#231541",
                    color: "white",
                    width: "80%",
                    height: "40px",
                    fontSize: "16px",
                    borderRadius: "50px",
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "purple",
                    },
                  }}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ColumnEditModal;

import React, { useState } from "react";
import { Box, Typography, Button, TextField, InputLabel } from "@mui/material";
import image from "../../../assets/images/registerCompany.png";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { CompanyRegister } from "../../../api/axios";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% + 20vh)",
    width: "100vw",
    backgroundColor: "#F1EAFF",
    padding: "2rem 15rem",

    [theme.breakpoints.down("lg")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  centerBox: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "red",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  boxLeft: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    backgroundColor: "white",
    padding: "3rem 4rem",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "1rem",
  },
  boxRight: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5rem",
    alignItems: "center",
    position: "relative",
    width: "50%",
    background:
      "linear-gradient(180deg, rgba(35, 21, 65, 1) 0%, rgba(226, 178, 255, 1) 180%)",
  },
}));

const initialValues = {
  companyName: "",
  companyEmail: "",
  companyNumber: "",
  companyAddress: "",
};
const RegisterCompany = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialValues);
  const [formError, setFormError] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  //const token = localStorage.getItem("tokenCompany");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormError({ ...formError, [id]: "" });
    setFormData({ ...formData, [id]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...formError };

    if (formData.companyName.trim() === "") {
      newErrors.companyName = "Please enter company name";
      isValid = false;
    } else {
      newErrors.companyName = "";
    }

    if (formData.companyEmail.trim() === "") {
      newErrors.companyEmail = "Please enter email";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.companyEmail)) {
      newErrors.companyEmail = "Email is invalid";
      isValid = false;
    } else {
      newErrors.companyEmail = "";
    }

    if (formData.companyNumber.trim() === "") {
      newErrors.companyNumber = "Please enter phone number";
      isValid = false;
    } else if (formData.companyNumber.trim().length < 10) {
      newErrors.companyNumber = "Phone number must be 10 digits";
      isValid = false;
    } else if (
      !/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.companyNumber)
    ) {
      newErrors.companyNumber = "Invalid phone number";
      isValid = false;
    } else {
      newErrors.companyNumber = "";
    }

    if (formData.companyAddress.trim() === "") {
      newErrors.companyAddress = "Please enter company address";
      isValid = false;
    } else {
      newErrors.companyAddress = "";
    }

    setFormError({ ...newErrors });
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestBody = {
          companyname: formData.companyName,
          companyemail: formData.companyEmail,
          contactnumber: formData.companyNumber,
          companyaddress: formData.companyAddress,
        };

        const response = await CompanyRegister(requestBody);
        //console.log(response)
        if (response.data.success) {
          CustomToast.success(response.data.message);
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          setLoading(false);
        } else {
          CustomToast.error(response.data.message);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response) {
          CustomToast.error(err.response.data.message);
          setLoading(false);
        } else {
          CustomToast.error("Something went wrong");
          setLoading(false);
        }
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.centerBox}>
        <Box className={classes.boxLeft}>
          <Typography
            sx={{ fontWeight: "600", fontFamily: "'Poppins', sans-serif" }}
            variant="h1"
          >
            Create Your Company
          </Typography>
          <Box className={classes.formBox}>
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-name"
            >
              Company Name
            </InputLabel>
            <TextField
              sx={{ mb: 1 }}
              error={formError.companyName ? true : false}
              id="companyName"
              label="Enter company name"
              variant="outlined"
              value={formData.companyName}
              onChange={handleChange}
            />
            {formError.companyName && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.companyName}
              </Typography>
            )}
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-email"
            >
              Company Email
            </InputLabel>
            <TextField
              sx={{ mb: 1 }}
              error={formError.companyEmail ? true : false}
              id="companyEmail"
              label="Enter company email"
              variant="outlined"
              value={formData.companyEmail}
              onChange={handleChange}
            />
            {formError.companyEmail && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.companyEmail}
              </Typography>
            )}
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-number"
            >
              Company Phone number
            </InputLabel>
            <TextField
              sx={{ mb: 1 }}
              error={formError.companyNumber ? true : false}
              id="companyNumber"
              label="Enter company number"
              variant="outlined"
              value={formData.companyNumber}
              onChange={handleChange}
            />
            {formError.companyNumber && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.companyNumber}
              </Typography>
            )}
            <InputLabel
              sx={{ mb: 1, fontSize: 15, fontFamily: "'Poppins', sans-serif" }}
              htmlFor="outlined-adornment-email"
            >
              Company Address
            </InputLabel>
            <TextField
              sx={{ mb: 1 }}
              error={formError.companyAddress ? true : false}
              id="companyAddress"
              label="Enter company address"
              variant="outlined"
              value={formData.companyAddress}
              onChange={handleChange}
            />
            {formError.companyAddress && (
              <Typography sx={{ color: "red", fontSize: 12, mb: 1 }}>
                {formError.companyAddress}
              </Typography>
            )}
            {/* <Link href="#" sx={{color:'blue',marginTop:2,textDecoration:'none',textAlign:'right'}}>Forget Password</Link> */}

            <Button
              sx={{
                marginTop: "2rem",
                backgroundColor: "#342352",
                color: "white",
                width: "100%",
                height: "40px",
                alignSelf: "center",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "Register"}
            </Button>
          </Box>
        </Box>
        <Box className={classes.boxRight}>
          <img
            src={image}
            width={600}
            height={600}
            style={{ position: "absolute", bottom: "-110px", right: "-90px" }}
            alt="login"
          />

          <Typography
            sx={{
              color: "white",
              fontSize: 45,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h1"
          >
            Hello, Friend
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 16,
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="p"
          >
            Enter your company details to register
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 20,
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
            }}
            variant="h2"
          >
            OR
          </Typography>
          <RouterLink to="/" style={{ textDecoration: "none", width: "50%" }}>
            <Button
              sx={{
                margin: "0.5rem 0",
                backgroundColor: "transparent",
                color: "white",
                width: "100%",
                height: "45px",
                alignSelf: "center",
                border: "1px solid rgba(255,255,255,0.5)",
                fontSize: "1rem",
                fontFamily: "'Poppins', sans-serif",
                textTransform: "capitalize",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "purple",
                },
                borderRadius: "50px",
              }}
              variant="contained"
              onClick={() => {
                localStorage.clear();
              }}
            >
              Login
            </Button>
          </RouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterCompany;

import {
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomToast from "../CustomToast/CustomToast"
import { updateUserDetails } from "../../api/axios";

const DetailEditModal = ({ show, setShow, item, userId, GetUserDetails }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
      contactNumber: item.contactNumber,
    });
  }, [item, show, setShow]);

  const handleClose = () => {
    setShow(false);
    setValidationErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    if (!formData.contactNumber.trim()) {
      errors.contactNumber = "Contact number is required";
    }
    if (!validatePhoneNumber(formData.contactNumber)) {
      errors.contactNumber = "Please enter a valid contact number";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const requestBody = {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactNumber: formData.contactNumber,
        };
        
        const res = await updateUserDetails(userId, requestBody);
        if (res.data.success) {
          CustomToast.success(res.data.message);
          setShow(!show);
          GetUserDetails(userId);
          handleClose();
        } else {
          CustomToast.error("Details update failed!");
        }
      } catch (error) {
        console.log("Error update details", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: 24,
            py: 4,
            px: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
            }}
          >
            Change User Details
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: 15,
                  fontFamily: "'Poppins', sans-serif",
                }}
                htmlFor="outlined-adornment-name"
              >
                First Name
              </InputLabel>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
                variant="filled"
                value={formData.firstName}
                onChange={handleInputChange}
                error={!!validationErrors.firstName}
              />
              {validationErrors.firstName && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {validationErrors.firstName}
                </FormHelperText>
              )}
            </Box>
            <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: 15,
                  fontFamily: "'Poppins', sans-serif",
                }}
                htmlFor="outlined-adornment-name"
              >
                Last Name
              </InputLabel>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name"
                variant="filled"
                value={formData.lastName}
                onChange={handleInputChange}
                error={!!validationErrors.lastName}
              />
              {validationErrors.lastName && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {validationErrors.lastName}
                </FormHelperText>
              )}
            </Box>
            <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: 15,
                  fontFamily: "'Poppins', sans-serif",
                }}
                htmlFor="outlined-adornment-name"
              >
                Email
              </InputLabel>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="text"
                label="Email"
                variant="filled"
                value={formData.email}
                onChange={handleInputChange}
                error={!!validationErrors.email}
              />
              {validationErrors.email && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {validationErrors.email}
                </FormHelperText>
              )}
            </Box>
            <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  fontSize: 15,
                  fontFamily: "'Poppins', sans-serif",
                }}
                htmlFor="outlined-adornment-name"
              >
                Contact Number
              </InputLabel>
              <TextField
                fullWidth
                id="contactNumber"
                name="contactNumber"
                type="text"
                label="Contact Number"
                variant="filled"
                value={formData.contactNumber}
                onChange={handleInputChange}
                error={!!validationErrors.email}
              />
              {validationErrors.contactNumber && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {validationErrors.contactNumber}
                </FormHelperText>
              )}
            </Box>
            <Box display="flex" justifyContent="right" mt="20px" gap="10px">
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#c82333",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#231541",
                  color: "white",
                  padding: "8px 40px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  fontFamily: "'Poppins', sans-serif",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "purple",
                  },
                }}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DetailEditModal;

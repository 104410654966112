import React, { useState, useRef, useEffect } from "react";
import "./ThreeDoteMenu.css";
import DeleteModal from "./../DeleteModalForBoard";
import CustomToast from "../CustomToast/CustomToast";

const ThreeDoteMenu = ({
  isThreeDoteModelOpen,
  setShowEditColumnModal,
  item,
  setIsThreeDoteModelOpen,
  tdmRef,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  const DeleteColumnHandler = async (e) => {
    e.stopPropagation();
    if (item.tasks.length > 0) {
      CustomToast.error(
        "You are not allowed to delete a non-empty progress stage."
      );
      return;
    }
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !tdmRef.current.contains(event.target)
      ) {
        setIsThreeDoteModelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {isThreeDoteModelOpen && (
        <div className="buttonContainerTDM" ref={menuRef}>
          <div
            className="buttonGroupTDM"
            onClick={() => {
              setShowEditColumnModal(true);
              setIsThreeDoteModelOpen(!isThreeDoteModelOpen);
            }}
          >
            Change status
          </div>
          <div
            className="buttonGroupTDM"
            onClick={(e) => {
              DeleteColumnHandler(e);
              setIsThreeDoteModelOpen(!isThreeDoteModelOpen);
            }}
          >
            Delete column
          </div>
        </div>
      )}

      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        title="progressStage"
        deleteID={item.id}
      />
    </div>
  );
};
export default ThreeDoteMenu;

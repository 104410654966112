import { Close } from "@mui/icons-material";
import React from "react";
const imageTypes = ["png", "jpg", "jpeg", "gif", "bmp", "webp"];
const videoTypes = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm", "mpeg"];
const AttachmentFullSizeView = ({
  setShowFullView,
  getFileTypeFromName,
  file,
}) => {
  const attachmentViewForFullScreen = () => {
    const fileType = getFileTypeFromName(file?.name);
    console.log(fileType);
    if (imageTypes.includes(fileType)) {
      return (
        <div>
          <img
            style={{ maxWidth: "80vw", maxHeight: "80vh", cursor: "pointer" }}
            src={file.link}
            alt=""
            onClick={() => setShowFullView(true)}
          />
        </div>
      );
    }
    if (videoTypes.includes(fileType)) {
      return (
        <div>
          <video
            style={{ maxWidth: "80vw", maxHeight: "80vh", cursor: "pointer" }}
            src={file.link}
            controls
            onClick={() => setShowFullView(true)}
          />
        </div>
      );
    }
  };

  return (
    <div
      onClick={() => setShowFullView(false)}
      style={{
        position: "fixed",
        top: 0,
        left: 0,

        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "5px",
          maxWidth: "90%",
          maxHeight: "90%",
          overflow: "auto",
        }}
      >
        {attachmentViewForFullScreen()}
      </div>
      <div
        className=" d-flex justify-content-center align-items-center attachment_full_view_Close_button "
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          height: "50px",
          width: "50px",
          borderRadius: "50% ",
          cursor: "pointer",
          border: "3px  solid  gray",
        }}
        onClick={() => setShowFullView(false)}
      >
        <Close
          className="attachment_full_view_Close_button_icon"
          style={{ fontSize: "27px", color: "gray" }}
        />
      </div>
    </div>
  );
};

export default AttachmentFullSizeView;
